export const getDataFileTypeByTypeId = (typeId: number) => {
  switch (typeId) {
    case 1:
      return 'referencePointData';
    case 2:
      return 'orthoData';
    case 3:
      return 'designData';
    case 4:
      return 'groundSurveyData';
    case 5:
      return 'asBuiltData';
    default:
      return '';
  }
};
