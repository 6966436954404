export type GenerateAPIHeaderRequest = {
  token: string;
  companyCode: string;
  openRandomString: string;
};

export type GenerateAPIHeaderResponse = {
  headers: {
    Authorization: string;
    'X-Mierukoji-Company-Code': string;
    'X-Open-Random-String': string;
  };
};

export const generateAPIHeader = (input?: GenerateAPIHeaderRequest): GenerateAPIHeaderResponse => ({
  headers: {
    //tokenがサーバーに渡されているか確認するところから
    //WARN ダミートークンを使用している。未認証ユーザーはverify時に弾かれる想定
    Authorization: input?.token || '',
    'X-Mierukoji-Company-Code': input?.companyCode || '',
    'X-Open-Random-String': input?.openRandomString || '',
  },
});
