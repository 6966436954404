import { MeUser } from '../types';

import { convertDataSizeUnitMBToGB } from '@/features/ownerUsers/utils/convertDataSizeUnit';
import { axios } from '@/lib/axios';
import { OutputErrorLog } from '@/lib/error';
import { GenerateAPIHeaderRequest, generateAPIHeader } from '@/utils/generateAPIHeader';

export const getUser = async (headers?: GenerateAPIHeaderRequest): Promise<MeUser | null> => {
  try {
    const apiHeaders = generateAPIHeader(headers);
    const result = await axios.get<MeUser>(`/users/me`, apiHeaders);
    return {
      ...result?.data,
      companyLimit: {
        ...result?.data.companyLimit,
        dataSizeLimit: convertDataSizeUnitMBToGB(result?.data.companyLimit.dataSizeLimit),
        dataSizeUsage: convertDataSizeUnitMBToGB(result?.data.companyLimit.dataSizeUsage),
      },
    };
  } catch (error) {
    OutputErrorLog('getUser', error);
    return null;
  }
};
