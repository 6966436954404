import { FinishedShapeDetailResponseDto } from '@/features/finishedShapeList/types';
import { axios } from '@/lib/axios';
import { OutputErrorLog } from '@/lib/error';
import { GenerateAPIHeaderRequest, generateAPIHeader } from '@/utils/generateAPIHeader';

// 出来形の個別詳細データ取得API
export const getFinishedShapeDetail = async ({
  siteId,
  headers,
  finishedShapeId,
}: {
  siteId: number;
  headers?: GenerateAPIHeaderRequest;
  finishedShapeId: number;
}): Promise<FinishedShapeDetailResponseDto | null> => {
  try {
    const apiHeaders = generateAPIHeader(headers);
    const result = await axios.get<FinishedShapeDetailResponseDto>(
      `/sites/${siteId}/finished-shapes/${finishedShapeId}`,
      apiHeaders
    );
    return result.data;
  } catch (error) {
    OutputErrorLog('getTaskAndFinishedShape', error);
    return null;
  }
};
