import { Icon } from '@iconify/react';
import {
  Anchor,
  Box,
  Button,
  Divider,
  Group,
  HoverCard,
  Header as MantineHeader,
  Stack,
  Text,
  createStyles,
  rem,
} from '@mantine/core';
import NextImage from 'next/image';
import { useSearchParams } from 'next/navigation';
import useSWR from 'swr';

import { AuthorizeLink } from '@/components/Ui/Elements/AuthorizeLink/AuthorizeLink';
import { getUser } from '@/features/auth/api/getUser';
import { useAuthorizeRouter } from '@/features/auth/hooks/useAuthorizeRouter';
import { getSite } from '@/features/saveSite/api/getSite';
import { SlSyncStatus } from '@/features/siteTop/types';
import { useDeleteCache } from '@/hooks';
import { signOut } from '@/lib/auth/auth';
import { Authorization } from '@/lib/auth/authorization';
import { formatDate } from '@/utils';
import { PAGE_PATHS, SERVICE_NAME, SWR_KEYS, SYSTEM_ROLE_ID } from '@/utils/constant';
import { PageType, getGlobalMenuLinks } from '@/utils/getGlobalMenuLinks';

const useStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    border: 'none',
    position: 'sticky',
    top: 0,
    // MEMO: なぜか横スクロールではなく、縦スクロールになっているが、step2ではレスポンシブ対応不要のため、一旦これで対応。
  },
  inner: {
    height: rem(56),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'white',
    flexWrap: 'wrap',
  },
  search: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },
  link: {
    display: 'block',
    lineHeight: 1,
    padding: `${rem(2)} ${rem(12)}`,
    textDecoration: 'none',
    color: 'white',
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    '&:hover': {
      opacity: '0.5',
    },
  },
  linkActive: {
    borderBottom: `1px solid white`,
    '&:hover': {
      opacity: '1',
    },
  },
}));

export function Header() {
  const router = useAuthorizeRouter();
  const { siteId } = router.query;
  const currentPath = router.pathname; // 動的ルーティングの値([siteId]など)はそのまま文字列として取得される（例：site/[siteId]/edit）
  const isSiteEditPage = currentPath === PAGE_PATHS.SITES.EDIT;
  //必ずしもsites/配下のページにいるとは限らないため、条件分岐を行う。
  const { data: site, isValidating } = useSWR([SWR_KEYS.SITE, siteId], () =>
    siteId ? getSite({ siteId }) : null
  );
  const { classes, cx } = useStyles();
  const params = useSearchParams();
  const companyCode = params.get('companyCode');

  const { data: user, isLoading: isLoadingUser } = useSWR(SWR_KEYS.USER, () => getUser());
  const pageType: PageType = (() => {
    // 工事の親ページは工事一覧(/sites)だが、新規工事登録(/sites/new)は例外的に親ページとして扱う
    if (currentPath === PAGE_PATHS.SITES.LIST || currentPath === PAGE_PATHS.SITES.NEW) {
      return 'sitesParent';
    }

    const topCurrentPath = `/${currentPath.split('/')[1]}`;
    switch (topCurrentPath) {
      case PAGE_PATHS.SITES.LIST:
        return 'sitesChild';
      case PAGE_PATHS.USERS.LIST:
        return 'users';
      case PAGE_PATHS.OWNER_USERS.LIST:
        return 'ownerUsers';
      default:
        return '' as PageType;
    }
  })();

  const links = user
    ? getGlobalMenuLinks({
        currentPath,
        siteId,
        user,
        pageType,
      })
    : [];
  const roleName = (() => {
    switch (user?.systemRoleTypeId) {
      case SYSTEM_ROLE_ID.SYSTEM_ADMIN_USER.id:
        return SYSTEM_ROLE_ID.SYSTEM_ADMIN_USER.name;
      case SYSTEM_ROLE_ID.TENANT_ADMIN_USER.id: {
        if (user.isSubstitute) return '代理操作';
        return SYSTEM_ROLE_ID.TENANT_ADMIN_USER.name;
      }

      case SYSTEM_ROLE_ID.GENERAL_USER.id:
        return SYSTEM_ROLE_ID.GENERAL_USER.name;
      case SYSTEM_ROLE_ID.UNAUTHENTICATED_USER.id:
        return SYSTEM_ROLE_ID.UNAUTHENTICATED_USER.name;

      default:
        return '';
    }
  })();
  const { deleteCache } = useDeleteCache();
  const onClickDeleteCache = () => {
    if (siteId) {
      deleteCache(siteId);
    }
  };

  const siteLinkSyncText = (() => {
    switch (site?.slSyncStatus) {
      case SlSyncStatus.SYNC_IN_PROGRESS:
        return {
          color: '#FCD34D',
          text: `${SERVICE_NAME.SITELINK}連携中`,
        };
      case SlSyncStatus.SYNC_SUCCESS:
        return {
          color: '#FCD34D',
          text: `${SERVICE_NAME.SITELINK}連携済`,
        };
      case SlSyncStatus.SERVER_ERROR:
      case SlSyncStatus.SYNC_FAILED:
        return {
          color: '#FA5252',
          text: `${SERVICE_NAME.SITELINK}連携失敗`,
        };

      default:
        return {
          color: '#FCD34D',
          text: '',
        };
    }
  })();
  const siteLinkApiUserScopeWarningText = (() => {
    if (!site?.slApiUserScope && site?.slSiteId) {
      return '(API USER POLICY未設定)';
    }
    return '';
  })();
  const isSiteLinkPath = currentPath === PAGE_PATHS.SITELINK;
  return (
    <MantineHeader
      height={{
        lg: 60, // 画面幅がブレークポイント1,200px以上の時
        base: 100, // ブレークポイント1,200px未満の時
      }}
      className={classes.header}
      bg="primary"
      // 工事編集ページでは、notificationsがモーダルのオーバレイの下に表示されてしまう現象を防ぐため、モーダルのz-indexを300に下げている。
      // デフォルトの1000だとヘッダーがモーダルの上に重なるため、100に下げて設定。
      // NOTE: なぜかブラウザ上では1つ加算されて101,1001になる
      zIndex={isSiteEditPage ? 100 : 1000}
    >
      <div className={classes.inner}>
        <Group>
          {/* ロゴ */}
          <Stack spacing="8px">
            <NextImage width={68} height={12} src="/images/company-logo.svg" alt="会社ロゴ" />
            <NextImage width={80} height={18} src="/images/application-logo.svg" alt="アプリロゴ" />
          </Stack>

          {/* 現在選択されている工事情報 */}
          {site && (
            <Group>
              <Authorization
                allowedRoles={[SYSTEM_ROLE_ID.TENANT_ADMIN_USER.id, SYSTEM_ROLE_ID.GENERAL_USER.id]}
              >
                <AuthorizeLink style={{ color: 'white' }} pathname={PAGE_PATHS.SITES.LIST}>
                  <Icon
                    icon="tabler:chevron-left"
                    height={16}
                    onClick={onClickDeleteCache}
                    style={{ cursor: 'pointer' }}
                  />
                </AuthorizeLink>
              </Authorization>
              <div>
                <Text truncate fz={18} fw={700} maw={400}>
                  {site?.siteName}
                </Text>
                <Group spacing="xs">
                  <Text fz={12}>{`${formatDate(
                    new Date(site?.scheduledStartDate),
                    'YYYY/MM/DD'
                  )} - ${formatDate(new Date(site?.scheduledEndDate), 'YYYY/MM/DD')}`}</Text>
                  <Group spacing={0}>
                    <Text fz={12} fw={700} c={siteLinkSyncText?.color}>
                      {siteLinkSyncText?.text}
                    </Text>
                    <Text fz={10} fw={700} c="#FA5252">
                      {siteLinkApiUserScopeWarningText}
                    </Text>
                  </Group>
                </Group>
              </div>
            </Group>
          )}
        </Group>
        {!isSiteLinkPath && (
          <Group spacing={30}>
            {/* メニュー項目 */}
            <Group ml={50} spacing={4}>
              {links.map((link) => (
                <AuthorizeLink
                  key={link.label}
                  pathname={link.link}
                  className={cx(classes.link, { [classes.linkActive]: link.isActive })}
                >
                  <Stack align="center" spacing={4}>
                    <NextImage width={24} height={24} src={link.imagePath} alt={link.label} />
                    <Text fw={700}>{link.label}</Text>
                  </Stack>
                </AuthorizeLink>
              ))}
            </Group>

            {/* ユーザー名 */}
            {user?.systemRoleTypeId === SYSTEM_ROLE_ID.UNAUTHENTICATED_USER.id ? (
              <Text
                fz={14}
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {user?.companyName}
              </Text>
            ) : (
              <HoverCard width={148} radius={8} shadow="xl">
                <HoverCard.Target>
                  <Stack spacing="none">
                    <Text
                      fz={14}
                      sx={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {isLoadingUser
                        ? ''
                        : user?.systemRoleTypeId === SYSTEM_ROLE_ID.UNAUTHENTICATED_USER.id
                        ? user?.companyName
                        : user?.systemRoleTypeId === SYSTEM_ROLE_ID.SYSTEM_ADMIN_USER.id
                        ? roleName
                        : `${user?.companyName}・${roleName}`}
                    </Text>

                    <Group spacing="xs">
                      <NextImage width={16} height={16} src="/images/user.svg" alt="ユーザー名" />
                      <Text
                        fz={14}
                        sx={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        {`${user?.familyName || ''} ${user?.givenName || ''}`}
                      </Text>
                    </Group>
                  </Stack>
                </HoverCard.Target>
                <HoverCard.Dropdown mah={185} color="red">
                  <Button
                    size="xs"
                    variant="outline"
                    radius="xl"
                    onClick={() => signOut(companyCode)}
                    w="100%"
                  >
                    ログアウト
                  </Button>
                  <Divider my={8} />
                  {/* 企業が見つからなかった場合はactiveSiteLimitが-1で返ってくるので非表示にする   https://github.com/ncdcdev/topcon-sitenow/pull/1796#issue-2250423668 */}
                  {user && user.systemRoleTypeId !== SYSTEM_ROLE_ID.SYSTEM_ADMIN_USER.id && (
                    <>
                      <Stack spacing={8}>
                        <Box>
                          <Text fz={12} fw={600} color="#71717A">
                            データ利用量(GB)
                          </Text>
                          <Text fz={14} color="black">
                            {`${user.companyLimit.dataSizeUsage
                              .toFixed(2)
                              .toLocaleString()} / ${user.companyLimit.dataSizeLimit.toLocaleString()}`}
                          </Text>
                        </Box>
                        <Box>
                          <Text fz={12} fw={600} color="#71717A">
                            アクティブ工事数
                          </Text>
                          <Text fz={14} color="black">
                            {`${user.companyLimit.activeSiteCount.toLocaleString()} / ${user.companyLimit.activeSiteLimit.toLocaleString()}`}
                          </Text>
                        </Box>
                      </Stack>

                      <Divider mt={8} />
                    </>
                  )}

                  <Box h={20} ta="right">
                    <Anchor
                      target="_blank"
                      href="https://manuals.sitenow.asia/2024/06/10/sitenow利用マニュアル/"
                      color="#0064BE"
                      fz={12}
                      fw={600}
                    >
                      使い方説明
                    </Anchor>
                  </Box>
                </HoverCard.Dropdown>
              </HoverCard>
            )}
          </Group>
        )}
      </div>
    </MantineHeader>
  );
}
