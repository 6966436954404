import { Site } from '../types';

import { axios } from '@/lib/axios';
import { OutputErrorLog } from '@/lib/error';
import { convertToJapaneseEra } from '@/utils/convertToJapaneseEra';
import { GenerateAPIHeaderRequest, generateAPIHeader } from '@/utils/generateAPIHeader';

const convertSite = (data: Site) => {
  const newData = {
    ...data,
    orderYear: convertToJapaneseEra(data.orderYear),
    customHoliday: data.customHoliday ? data.customHoliday.split(',') : [],
    regularHoliday: data.regularHoliday ? data.regularHoliday.split(',') : [],
    contractAmount:
      data.contractAmount === undefined || data.contractAmount === null
        ? undefined
        : data.contractAmount,
  };
  return newData;
};

//サイト更新API
// eslint-disable-next-line consistent-return
export const getSite = async ({
  siteId,
  headers,
  isFetchSl = false,
}: {
  siteId: number;
  headers?: GenerateAPIHeaderRequest;
  isFetchSl?: boolean;
  // eslint-disable-next-line consistent-return
}): Promise<Site | null> => {
  try {
    const apiHeaders = generateAPIHeader(headers);
    const result = await axios.get<Site>(
      `/sites/${siteId}?isFetchSl=${isFetchSl}
    `,
      apiHeaders
    );
    const newData = convertSite(result.data);
    //WARN ここで型を変換しているが、型を変換するのは本来ここではない
    return newData as any;
  } catch (error) {
    OutputErrorLog('getSite', error);
    return null;
  }
};
