import { Authenticator } from '@aws-amplify/ui-react';
import { MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { Notifications } from '@mantine/notifications';
import { RecoilRoot } from 'recoil';
import { SWRConfig } from 'swr';

import { Layout } from '@/components/Layout';
import { CommonMeta } from '@/components/Ui/CommonMeta';
import { ClientAuthorizeProvider } from '@/providers/client-authorize-provider';
import { PollingProvider } from '@/providers/polling-provider';
import { theme } from '@/utils/constant';

type AppProviderProps = {
  children: React.ReactNode;
  fallback?: {
    [x: string]: any;
  };
};

export const AppProvider = ({ children, fallback }: AppProviderProps) => (
  <SWRConfig
    value={{
      fallback,
      onErrorRetry(err, key, config, revalidate, revalidateOpts) {
        //404エラーの場合はリトライしない
        const isNotFound = String(err).includes('NotFoundError') || String(err).includes('404');

        if (!isNotFound && revalidateOpts.retryCount < 10) {
          revalidate(revalidateOpts);
        }
      },
    }}
  >
    <RecoilRoot>
      <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
        <Notifications limit={3} />
        <DatesProvider settings={{ locale: 'ja', firstDayOfWeek: 0, weekendDays: [0] }}>
          <Authenticator.Provider>
            <ClientAuthorizeProvider>
              <Layout>
                <CommonMeta />
                <PollingProvider>{children}</PollingProvider>
              </Layout>
            </ClientAuthorizeProvider>
          </Authenticator.Provider>
        </DatesProvider>
      </MantineProvider>
    </RecoilRoot>
  </SWRConfig>
);
