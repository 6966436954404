import { axios } from '@/lib/axios';
import { OutputErrorLog } from '@/lib/error';

export const slSyncHeightMapReport = async (siteId: number) => {
  try {
    return await axios.post(`/sites/${siteId}/sl-report/height-map-report/sync`);
  } catch (error) {
    OutputErrorLog('transferDataFile', error);
    return null;
  }
};
