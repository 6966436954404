// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';

export type AuthorizePagination = {
  //path
  pathname: string;
  options?: {
    //デフォルトで保持するquery
    defaultQuery?: {
      [key: string]: string | string[] | undefined;
    };
    //追加するquery
    addQuery?: {
      [key: string]: string | string[] | undefined;
    };
    //除外するquery
    excludeQuery?: string[];
  };
};

type ExtendedNextRouter = ReturnType<typeof useRouter> & {
  query: {
    siteId?: number;
    taskId?: number;
    finishedShapeId?: number;
    companyId?: number;
    userId?: number;
    companyCode?: string;
    openRandomString?: string;
  };
};

export const useAuthorizeRouter = () => {
  const router: ExtendedNextRouter = useRouter();
  if (router?.query?.siteId) {
    router.query.siteId = Number(router.query.siteId);
  }
  if (router?.query?.taskId) {
    router.query.taskId = Number(router.query.taskId);
  }
  if (router?.query?.finishedShapeId) {
    router.query.finishedShapeId = Number(router.query.finishedShapeId);
  }
  if (router?.query?.companyId) {
    router.query.companyId = Number(router.query.companyId);
  }
  if (router?.query?.userId) {
    router.query.userId = Number(router.query.userId);
  }
  //openRandomStringとcompanyCodeの存在するqueryを保持する
  const defaultQueryOptions = {
    openRandomString: (router.query?.openRandomString || '') as string,
    companyCode: (router.query?.companyCode || '') as string,
  };

  const push = async (input: AuthorizePagination) => {
    const { pathname, options } = input;
    const { defaultQuery, addQuery, excludeQuery } = options || {};
    const query = {
      ...defaultQueryOptions,
      ...defaultQuery,
      ...addQuery,
    };
    excludeQuery?.forEach((key) => {
      //@ts-ignore
      delete query[key];
    });
    //値が存在しないqueryは削除する
    Object.keys(query).forEach((key) => {
      //@ts-ignore
      if (query[key] === undefined) {
        //@ts-ignore
        delete query[key];
      }
    });

    await router.push({
      pathname,
      query,
    });
  };

  return {
    ...router,
    push,
  };
};
