import { FC } from 'react';

import { Footer } from './Footer';
import { Header } from './Header';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout: FC<MainLayoutProps> = ({ children }) => (
  <div style={{ background: '#F8F9FA', minWidth: '100vw', minHeight: '100vh' }}>
    <Header />
    <main>{children}</main>
    <Footer />
  </div>
);
