import { notifications } from '@mantine/notifications';
import dayjs from 'dayjs';
import { useRecoilState, useSetRecoilState } from 'recoil';
import useSWR, { mutate } from 'swr';

import { useAuthorizeRouter } from '../../auth/hooks/useAuthorizeRouter';
import { useConstraints } from '../../auth/hooks/useConstraints';

import { getUser } from '@/features/auth/api/getUser';
import { getFinishedShapeDetail } from '@/features/finishedShapeListTask/api/getFinishedShapeDetail';
import { getDataFile } from '@/features/siteTop/api/getDataFile';
import { getDataFiles } from '@/features/siteTop/api/getDataFiles';
import { getResourceStatus } from '@/features/siteTop/api/getResourceStatus';
import { slSyncHeightMapReport } from '@/features/siteTop/api/slSyncHeightMapReport';
import { generateDefaultDataByDataFileType } from '@/features/siteTop/lib/generateDefaultData';
import { getDataFileTypeByTypeId } from '@/features/siteTop/lib/getDataFileTypeByTypeId';
import { designDataFilterState, displayedDataFileState } from '@/features/siteTop/store/atom';
import { DataFileType, DataFile as DataFileTypeAlias } from '@/features/siteTop/types';
import { useSiteLinkValid } from '@/features/sitelink/hooks/useSitelinkAuth';
import { OutputErrorLog } from '@/lib/error';
import { prevResourceStatusDataState } from '@/store/atom';
import { ALL_DATA_FILE_EXTENSIONS, SWR_KEYS } from '@/utils/constant';
import { getNotificationDetail } from '@/utils/getNotificationDetail';

//https://github.com/ncdcdev/topcon-sitenow/pull/1663
export enum ProcessStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  DELETE_IN_PROGRESS = 'DELETE_IN_PROGRESS',
  FAILED = 'FAILED',
  //レスポンスには存在しないが、通知のため成功時のステータスを追加
  SUCCESS = 'SUCCESS',
}

export enum ResourceType {
  //容量移動
  Site = 'site',
  //データファイルの変換・SL連携・削除
  DataFile = 'dataFile',
  //帳票作成・更新
  FinishedShape = 'finishedShape',
  //施工履歴データの取得
  HeightMapReport = 'heightMapReport',
  SoilAmountCalculation = 'SoilAmountCalculation',
}

export enum DataFileProcessType {
  Transfer = 'transfer',
  SlSync = 'slSync',
}

export type ResourceStatus = {
  resourceId: number;
  resourceType: ResourceType;
  processType?: DataFileProcessType;
  processStatus: ProcessStatus;
  isTIN?: boolean;
};

export const usePolling = () => {
  const router = useAuthorizeRouter();
  const { siteId } = router.query;
  const { data: user } = useSWR(SWR_KEYS.USER, () => getUser());

  const { isValid } = useSiteLinkValid();
  const constraints = useConstraints();
  const { data: dataFiles = [], isLoading: isLoadingDataFiles } = useSWR(
    SWR_KEYS.DATA_FILE_LIST,
    () => (siteId ? getDataFiles({ siteId }) : [])
  );
  const setDisplayedDataFile = useSetRecoilState(displayedDataFileState);
  const [designDataFilter, setDesignDataFilter] = useRecoilState(designDataFilterState);
  const isCreateSlSyncExecutable =
    !constraints.isSiteDone && !constraints.isDataSizeLimitReached && siteId && isValid;
  useSWR(
    [SWR_KEYS.SITE_LINK.DESIGN_DATA_DOWNLOAD, siteId, isValid],
    () => {
      //siteIdが存在し、slSiteIdとslApiUserScopeが存在する場合のみ、データを取得する。未認証の場合は呼び出さない
      if (isCreateSlSyncExecutable) {
        slSyncHeightMapReport(siteId);
      }
    },
    {
      refreshInterval: 1000 * 60 * 5,
      //必ず、ポーリングのタイミングだけでAPIを呼び出す
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const [prevResourceStatusData, setPrevResourceStatusData] = useRecoilState(
    prevResourceStatusDataState
  );
  const updateNotificationSystem = async (
    resourceStatus: ResourceStatus,
    options: {
      isNew: boolean;
      resourceName: string | null;
      prevResourceStatus?: ResourceStatus;
    }
  ) => {
    const { isNew, resourceName, prevResourceStatus } = options;
    if (!siteId) return;
    //MEMO 一時的に帳票では通知は行わない
    if (resourceStatus.resourceType === ResourceType.FinishedShape) return;
    let newDataFiles = dataFiles;
    //DataFileのResourceで、該当のIDがdataFilesに存在しない場合は、再取得する
    const dataFile = dataFiles.find((item) => item.id === resourceStatus.resourceId);
    if (!dataFile && resourceStatus.resourceType === ResourceType.DataFile) {
      newDataFiles = await getDataFiles({ siteId });
    }

    const notificationDetail = getNotificationDetail(resourceStatus, {
      resourceName,
      prevResourceStatus,
      dataFiles: newDataFiles,
    });
    if (!notificationDetail.title) return;
    if (isNew) {
      notifications.show({
        id: resourceStatus.resourceId + resourceStatus.resourceType,
        title: notificationDetail.title,
        message: notificationDetail.message,
        color: notificationDetail.color,
        loading: notificationDetail.loading,
        autoClose: notificationDetail.autoClose,
      });
      return;
    }
    notifications.update({
      id: resourceStatus.resourceId + resourceStatus.resourceType,
      title: notificationDetail.title,
      message: notificationDetail.message,
      color: notificationDetail.color,
      loading: notificationDetail.loading,
      autoClose: notificationDetail.autoClose,
    });
  };
  const refetcher = async (resourceStatus: ResourceStatus) => {
    if (!siteId) return;
    switch (resourceStatus.resourceType) {
      case ResourceType.DataFile:
        await mutate([SWR_KEYS.DATA_FILE_LIST, siteId]);
        await mutate([SWR_KEYS.DATA_FILE_LIST_QUERY, siteId, designDataFilter]);
        await mutate([SWR_KEYS.DATA_FILE_LIST_QUERY_REFERENCE_POINT, siteId]);
        break;
      case ResourceType.FinishedShape:
        await mutate([SWR_KEYS.FINISHED_SHAPE_LIST, siteId]);
        await mutate([[SWR_KEYS.FINISHED_SHAPE_DETAIL, resourceStatus.resourceId, siteId]]);
        break;
      case ResourceType.Site:
        await mutate([SWR_KEYS.SITE, siteId]);
        await mutate([SWR_KEYS.SITE_WITH_SL, siteId]);
    }
  };
  const getResourceName = async (resourceStatus: ResourceStatus) => {
    if (!siteId) return null;
    switch (resourceStatus.resourceType) {
      case ResourceType.DataFile: {
        const data = await getDataFile({
          siteId,
          dataFileId: resourceStatus.resourceId,
        });
        return data?.fileName || null;
      }
      case ResourceType.FinishedShape: {
        const data = await getFinishedShapeDetail({
          siteId,
          finishedShapeId: resourceStatus.resourceId,
        });
        return data?.taskName || null;
      }
      default:
        return null;
    }
  };
  const updateDisplayedDataFile = (dataFile: DataFileTypeAlias) => {
    const key = getDataFileTypeByTypeId(dataFile.typeId);
    setDisplayedDataFile((prev) => {
      //設計データは配列であることを考慮する
      if (key === 'designData') {
        const newDesignData = prev.designData.map((item) => {
          if (item.data.id === dataFile.id) {
            return {
              ...item,
              data: dataFile,
            };
          }
          return item;
        });
        const isDataFileIncluded = newDesignData.some((item) => item.data.id === dataFile.id);
        if (!isDataFileIncluded) {
          newDesignData.push({
            isExist: false,
            data: dataFile,
          });
        }

        return {
          ...prev,
          designData: newDesignData,
        };
      }
      return {
        ...prev,
        [key]: {
          isExist: true,
          data: dataFile,
        },
      };
    });
  };

  const manageNotify = async (data: ResourceStatus[]) => {
    try {
      let dataArray = [...data];
      /*失敗したものへの処理*/
      const failedResourceStatus = dataArray.filter((item) =>
        prevResourceStatusData.some(
          (prevItem) =>
            item.resourceId === prevItem.resourceId &&
            item.resourceType === prevItem.resourceType &&
            item.processStatus === ProcessStatus.FAILED &&
            prevItem.processStatus !== ProcessStatus.FAILED
        )
      );
      //重複処理を防ぐため、dataArrayからfailedResourceStatusを削除
      dataArray = dataArray.filter(
        (item) =>
          !failedResourceStatus.some(
            (failedItem) =>
              item.resourceId === failedItem.resourceId &&
              item.resourceType === failedItem.resourceType
          )
      );
      const failedPromisses = failedResourceStatus.map(async (item) => {
        const resourceName = await getResourceName(item);
        await updateNotificationSystem(item, {
          isNew: false,
          resourceName,
        });
        await refetcher(item);
      });
      /*新しく追加になったものへの処理*/
      const newResourceStatus = dataArray
        .filter(
          (item) =>
            !prevResourceStatusData.some(
              (prevItem) =>
                item.resourceId === prevItem.resourceId &&
                item.resourceType === prevItem.resourceType
            )
        )
        //失敗したものは除外
        .filter((item) => item.processStatus !== ProcessStatus.FAILED);
      //FAILEDからIN_PROGRESSになったものは新規として扱う
      const resourceStatusFailedToInProgress = dataArray.filter((item) =>
        prevResourceStatusData.some(
          (prevItem) =>
            item.resourceId === prevItem.resourceId &&
            item.resourceType === prevItem.resourceType &&
            item.processStatus === ProcessStatus.IN_PROGRESS &&
            prevItem.processStatus === ProcessStatus.FAILED
        )
      );
      newResourceStatus.push(...resourceStatusFailedToInProgress);
      dataArray = dataArray.filter(
        (item) =>
          !newResourceStatus.some(
            (newItem) =>
              item.resourceId === newItem.resourceId && item.resourceType === newItem.resourceType
          )
      );
      const newPromisses = newResourceStatus.map(async (item) => {
        const resourceName = await getResourceName(item);
        await updateNotificationSystem(item, {
          isNew: true,
          resourceName,
        });
      });
      /*成功したものへの処理。prevに存在していて、最新のデータに存在しないものは成功したものとして扱う*/
      const successResourceStatus = prevResourceStatusData.filter(
        (prevItem) =>
          !dataArray.some(
            (item) =>
              item.resourceId === prevItem.resourceId && item.resourceType === prevItem.resourceType
          )
      );

      const successPromisses = successResourceStatus.map(async (item) => {
        const resourceName = await getResourceName(item);
        const prevResourceStatus = prevResourceStatusData.find(
          (prevItem) =>
            item.resourceId === prevItem.resourceId && item.resourceType === prevItem.resourceType
        );
        await updateNotificationSystem(
          {
            ...item,
            processStatus: ProcessStatus.SUCCESS,
          },
          {
            isNew: false,
            resourceName,
            prevResourceStatus,
          }
        );
        await refetcher(item);
        //DataFileの場合は、3Dを更新する

        if (item.resourceType === ResourceType.DataFile && siteId) {
          const dataFile = await getDataFile({
            siteId,
            dataFileId: item.resourceId,
          });
          if (
            prevResourceStatus?.processStatus === ProcessStatus.DELETE_IN_PROGRESS &&
            prevResourceStatus?.processType === DataFileProcessType.SlSync &&
            prevResourceStatus?.isTIN
          ) {
            //TIN削除完了の場合は、紀行測量データを非表示にする
            setDisplayedDataFile((prev) => ({
              ...prev,
              groundSurveyData: {
                isExist: false,
                data: generateDefaultDataByDataFileType(DataFileType.GROUND_SURVEY, {
                  measurementDate: dayjs().format('YYYY-MM-DD'),
                }),
              },
            }));
          }
          if (!dataFile) {
            return;
          }
          //設計データに関しては、XMLの場合のみ表示する
          if (dataFile.typeId === DataFileType.DESIGN) {
            const isXML = ALL_DATA_FILE_EXTENSIONS.xml.includes(dataFile.fileExt);
            if (!isXML) return;
          }

          updateDisplayedDataFile(dataFile);
        }
      });
      await Promise.all([...failedPromisses, ...newPromisses, ...successPromisses]);
      setPrevResourceStatusData(data);
    } catch (e) {
      OutputErrorLog('manageNotify', e);
    }
  };
  useSWR(
    [SWR_KEYS.RESOURCE_STATUS.IN_PROGRESS, siteId],
    () => (siteId ? getResourceStatus(siteId) : []),
    {
      refreshInterval: 5000,
      onSuccess: (data) => {
        if (isLoadingDataFiles) return;
        manageNotify(data);
      },
    }
  );
};
