//データファイル(複数)取得API

import { convertDataFileListTaskIdList } from '../lib/convertDataFile';
import { DesignDataFilterState } from '../store/atom';
import { DataFile, DataFileEntity } from '../types';

import { axios } from '@/lib/axios';
import { OutputErrorLog } from '@/lib/error';
import { GenerateAPIHeaderRequest, generateAPIHeader } from '@/utils/generateAPIHeader';

export type GetDataFilesQuery = Partial<DesignDataFilterState>;

export const getDataFiles = async ({
  siteId,
  headers,
  query,
}: {
  siteId: number;
  headers?: GenerateAPIHeaderRequest;
  query?: GetDataFilesQuery;
  // eslint-disable-next-line consistent-return
}): Promise<DataFile[]> => {
  const fileExt = (() => {
    if (!query?.fileExt) return [];
    const lowerCase = query?.fileExt;
    const upperCase = query?.fileExt?.map((ext) => ext.toUpperCase());
    return [...lowerCase, ...upperCase];
  })();
  try {
    const apiHeaders = generateAPIHeader(headers);
    const result = await axios.get<DataFileEntity[]>(`/sites/${siteId}/data-file`, {
      ...apiHeaders,
      params: {
        ...query,
        //小文字で渡ってくるため、大文字も渡す
        fileExt,
      },
    });

    return convertDataFileListTaskIdList(result.data);
  } catch (error) {
    OutputErrorLog('getDataFiles', error);
    return [];
  }
};
