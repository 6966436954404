import { unstable_serialize } from 'swr';

import { GenerateAPIHeaderRequest } from '../utils/generateAPIHeader';

import { OutputErrorLog } from './error';

import { getUser } from '@/features/auth/api/getUser';
import { getFinishedShapeList } from '@/features/finishedShapeList/api';
import { getFinishedShapeDetail } from '@/features/finishedShapeListTask/api/getFinishedShapeDetail';
import { getConstructionZones } from '@/features/saveSite/api/getConstructionZones';
import { getSite } from '@/features/saveSite/api/getSite';
import { getSites } from '@/features/siteList/api';
import { getDataFiles } from '@/features/siteTop/api/getDataFiles';
import { DataFileType } from '@/features/siteTop/types';
import { ExtendedAppContext } from '@/pages/_app';
import { PAGE_PATHS, SWR_KEYS } from '@/utils/constant';

export const getInitialData = async (appContext: ExtendedAppContext, idToken: string) => {
  try {
    //https://swr.vercel.app/ja/docs/with-nextjs#complex-keys
    const fallback: { [x: string]: {} } = {};
    const companyCode = appContext.ctx.query.companyCode as string;
    const openRandomString = appContext.ctx.query.openRandomString as string;
    const headers: GenerateAPIHeaderRequest = {
      token: idToken,
      companyCode,
      openRandomString,
    };

    let { siteId, finishedShapeId } = appContext.ctx.query;
    if (siteId) {
      siteId = Number(siteId);
      const site = await getSite({
        siteId,
        headers,
      });
      if (site) {
        fallback[unstable_serialize([SWR_KEYS.SITE, siteId])] = site;
      }
      fallback[unstable_serialize([SWR_KEYS.CONSTRUCTION_ZONE_LIST, siteId])] =
        await getConstructionZones({ siteId, headers });
      fallback[unstable_serialize([SWR_KEYS.DATA_FILE_LIST, siteId])] = await getDataFiles({
        siteId,
        headers,
      });
      fallback[unstable_serialize([SWR_KEYS.DATA_FILE_LIST_QUERY_REFERENCE_POINT, siteId])] =
        await (
          await getDataFiles({
            siteId,
            headers,
          })
        ).filter((dataFile) => dataFile.typeId === DataFileType.REFERENCE_POINT);
      fallback[unstable_serialize([SWR_KEYS.FINISHED_SHAPE_LIST, siteId])] =
        await getFinishedShapeList({ siteId, headers });
    }
    if (finishedShapeId && siteId) {
      siteId = Number(siteId);
      finishedShapeId = Number(finishedShapeId);
      const finishedShape = await getFinishedShapeDetail({
        siteId,
        headers,
        finishedShapeId,
      });
      if (finishedShape) {
        fallback[unstable_serialize([SWR_KEYS.FINISHED_SHAPE_DETAIL, siteId, finishedShapeId])] =
          finishedShape;
      }
    }
    const currentPage = `/${appContext.ctx.pathname.split('/')[1]}`;
    if (currentPage === PAGE_PATHS.SITES.LIST) {
      const user = await getUser(headers);
      if (user) {
        fallback[SWR_KEYS.USER] = user;
      }
      fallback[SWR_KEYS.SITE_LIST] = await getSites({ headers });
    }

    return fallback;
  } catch (error) {
    OutputErrorLog('getinitialData', error);
    return {};
  }
};
