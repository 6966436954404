import useSWR from 'swr';

import { useAuthorizeRouter } from './useAuthorizeRouter';

import { getUser } from '@/features/auth/api/getUser';
import { getSite } from '@/features/saveSite/api/getSite';
import { Constraints } from '@/lib/constraints';
import { SWR_KEYS } from '@/utils/constant';

export const useConstraints = (): Constraints => {
  const router = useAuthorizeRouter();
  const { siteId } = router.query;
  const { data: user, isLoading: isLoadingUser } = useSWR(SWR_KEYS.USER, () => getUser());
  const { data: site, isLoading: isLoadingSite } = useSWR([SWR_KEYS.SITE, siteId], () =>
    siteId ? getSite({ siteId }) : null
  );

  return {
    //現場作成ページなど、現場が存在しない場合を考慮する
    isSiteDone: isLoadingSite ? false : site ? !site?.isActive : false,
    isActiveSiteLimitReached: isLoadingUser
      ? false
      : user?.companyLimit.activeSiteLimitReached ?? true,
    isDataSizeLimitReached: isLoadingUser ? false : user?.companyLimit.dataSizeLimitReached ?? true,
  };
};
