import { useMemo } from 'react';

import useSWR from 'swr';

import { useAuthorizeRouter } from '@/features/auth/hooks/useAuthorizeRouter';
import { getSite } from '@/features/saveSite/api/getSite';
import { SlSyncStatus } from '@/features/siteTop/types';
import { SITELINK_AUTH_ORIGIN, SWR_KEYS } from '@/utils/constant';

export const useCreateSitelinkAuthURL = (args: { clientId: string; redirectUri: string }) => {
  const { clientId, redirectUri } = args;

  // NOTE: local開発時は遷移後の画面で下記を実行してください
  // document.querySelector("#oauth > input[type=hidden]:nth-child(2)").value = "http://localhost:3002/sitelink"
  const url = useMemo(() => {
    if (typeof window === 'undefined') return '';
    const randomStr = crypto.randomUUID();
    localStorage.setItem('siteLinkRandomStr', randomStr);
    return `${SITELINK_AUTH_ORIGIN}/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&state=${randomStr}`;
  }, [clientId, redirectUri]);

  return url;
};

export const useSiteLinkValid = () => {
  const router = useAuthorizeRouter();
  const { siteId } = router.query;
  const { data: site, isLoading } = useSWR([SWR_KEYS.SITE, siteId], () =>
    siteId ? getSite({ siteId }) : null
  );
  const isValid = !!(
    site?.slSiteId &&
    site?.slApiUserScope &&
    site.slSyncStatus === SlSyncStatus.SYNC_SUCCESS
  );
  return {
    isValid,
    isLoading,
    invalidState: {
      slApiUserScope: !site?.slApiUserScope,
      slSyncStatus: site?.slSyncStatus !== SlSyncStatus.SYNC_SUCCESS,
    },
    site,
  };
};
