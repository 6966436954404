import { Box, Group, Text } from '@mantine/core';

import { COMMIT_ID, VERSION } from '@/utils/constant';

export const Footer = () => {
  const v = `v ${VERSION}.${COMMIT_ID?.slice(0, 7)}`;
  return (
    <Box pos="fixed" bottom={0} w="100vw" h="20px" bg="rgba(255, 255, 255, 0.8)">
      <Group px={16} position="right" h="100%" w="100%" align="center" spacing={8}>
        <Text c="rgba(138, 138, 138, 1)" fz={10} fw={500}>
          ©2024 Topcon Sokkia Positioning Japan Co.,Ltd.
        </Text>
        <Text c="rgba(138, 138, 138, 1)" fz={10} fw={500}>
          {v}
        </Text>
      </Group>
    </Box>
  );
};
