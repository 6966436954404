import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { MantineThemeOverride } from '@mantine/core';

import { SystemRoleTypeId } from '@/features/auth/types';
import { EnCoordinate } from '@/features/siteTop/lib/transformCoordinate';
import { FileExt } from '@/features/siteTop/types';

/**
 * Cesium関連の定数
 */
export interface SiteConfig {
  offsetHeight: number;
  originPoint: Array<number>;
  coordinateLatLong: Array<number>;
}

export const FORMATS = {
  Surveying: 'Surveying',
  AsBuilt: 'AsBuilt',
  GEO_TIF: 'geotif',
  DesignData: 'DesignData',
  StandardScore: 'StandardScore',
};

export const ID_VIEWER = 'cesium-viewer-custom';

export const OPTIONS_POINTS = Array.from(Array(10), (_, index) => ({
  value: String(index + 1),
  label: String(index + 1),
}));

//系番号1系
export const DEFAULT_EPSG = 6669;

export const COORDINATE = [
  { label: '数学座標', value: 'mathematical' as EnCoordinate },
  { label: '測量座標', value: 'surveying' as EnCoordinate },
];

export type SelectOption = { value: string; label: string };

/**
 * 拡張子対応表
 */
export const REFERENCE_POINT_FILE_EXTENSIONS = {
  lower: ['sim', 'csv', 'txt'] as FileExt[],
  upper: ['SIM', 'CSV', 'TXT'] as FileExt[],
  all: ['sim', 'csv', 'txt', 'SIM', 'CSV', 'TXT'] as FileExt[],
};

export const ORTHO_IMAGE_FILE_EXTENSIONS = {
  lower: ['tif', 'tiff'] as FileExt[],
  upper: ['TIF', 'TIFF'] as FileExt[],
  all: ['tif', 'tiff', 'TIF', 'TIFF'] as FileExt[],
};

export const DESIGN_FILE_EXTENSIONS = {
  lower: ['xml', 'tp3', 'tn3'] as FileExt[],
  upper: ['XML', 'TP3', 'TN3'] as FileExt[],
  all: ['xml', 'tp3', 'tn3', 'XML', 'TP3', 'TN3'] as FileExt[],
};

//MEMO 出来高と起工測量は拡張子が同じ
export const GROUND_SURVEY_FILE_EXTENSIONS = {
  lower: ['las', 'csv', 'txt', 'xyz'] as FileExt[],
  upper: ['LAS', 'CSV', 'TXT', 'XYZ'] as FileExt[],
  all: ['las', 'csv', 'txt', 'xyz', 'LAS', 'CSV', 'TXT', 'XYZ'] as FileExt[],
};

export const AS_BUILT_FILE_EXTENSIONS = {
  lower: ['las', 'csv', 'txt', 'xyz'] as FileExt[],
  upper: ['LAS', 'CSV', 'TXT', 'XYZ'] as FileExt[],
  all: ['las', 'csv', 'txt', 'xyz', 'LAS', 'CSV', 'TXT', 'XYZ'] as FileExt[],
};
export const SL_LOCALIZATION_FILE_EXTENSIONS = {
  lower: ['tp3', 'gc3'] as FileExt[],
  upper: ['TP3', 'GC3'] as FileExt[],
  all: ['tp3', 'gc3', 'TP3', 'GC3'] as FileExt[],
};

export const SL_GEOID_FILE_EXTENSIONS = {
  lower: ['gff'] as FileExt[],
  upper: ['GFF'] as FileExt[],
  all: ['gff', 'GFF'] as FileExt[],
};

export const ALL_DATA_FILE_EXTENSIONS: {
  [key in string]: FileExt[];
} = {
  xml: ['xml', 'XML'],
  tp3: ['tp3', 'TP3'],
  tn3: ['tn3', 'TN3'],
  las: ['las', 'LAS'],
  csv: ['csv', 'CSV'],
  txt: ['txt', 'TXT'],
  xyz: ['xyz', 'XYZ'],
  sim: ['sim', 'SIM'],
  tif: ['tif', 'TIF'],
  tiff: ['tiff', 'TIFF'],
  gc3: ['gc3', 'GC3'],
  gff: ['gff', 'GFF'],
};

export const TEXT_DATA_EXTENSIONS = ['sim', 'csv', 'txt', 'xyz', 'CSV', 'TXT', 'XYZ', 'SIM'];

/**
 * 認証・権限関連の定数
 */

//404以外の全てのページパス。[siteId]などの動的な部分はreplaceで置換する
export const PAGE_PATHS = {
  TOP: '/',
  LOGIN: '/login',
  SITELINK: '/sitelink',
  SITES: {
    LIST: '/sites',
    NEW: '/sites/new',
    EDIT: '/sites/[siteId]/edit',
    SHOW: '/sites/[siteId]',
    SCHEDULE: '/sites/[siteId]/schedule',
    FINISHED_SHAPE_PROGRESS: '/sites/[siteId]/finished-shape-progress',
    FINISHED_SHAPES: {
      LIST: '/sites/[siteId]/finished-shapes',
      NEW: '/sites/[siteId]/finished-shapes/new',
      EDIT: '/sites/[siteId]/finished-shapes/edit',
      DETAIL: '/sites/[siteId]/finished-shapes/[finishedShapeId]',
      REPORTS: '/sites/[siteId]/finished-shapes/[finishedShapeId]/reports',
    },
  },
  USERS: {
    LIST: '/users',
    NEW: '/users/new',
    EDIT: '/users/[userId]/edit',
    ME: '/users/me',
  },
  OWNER_USERS: {
    LIST: '/owner-users',
    NEW: '/owner-users/new',
    EDIT: '/owner-users/[companyId]/edit',
  },
  AUTH: '/api/auth',
};

export const DYNAMIC_PATHS: {
  [key in string]: DynamicPaths;
} = {
  SITE_ID: '[siteId]',
  FINISHED_SHAPE_ID: '[finishedShapeId]',
  USER_ID: '[userId]',
  COMPANY_ID: '[companyId]',
};

export type DynamicPaths = '[siteId]' | '[finishedShapeId]' | '[userId]' | '[companyId]';

export const DYNAMIC_PATHS_ARRAY: DynamicPaths[] = Object.values(DYNAMIC_PATHS);

//https://github.com/ncdcdev/mierukoji-app/blob/ec0f2c62f93d8a9ec3f7abfb7a9efd2c514df9bf/amplify/backend/api/mierukojiapi/src/commonConstants.ts#L195
export const SYSTEM_ROLE_ID = {
  UNAUTHENTICATED_USER: {
    id: 0 as SystemRoleTypeId,
    name: '未認証ユーザー',
  },
  TENANT_ADMIN_USER: {
    id: 1 as SystemRoleTypeId,
    name: 'テナント管理者',
  },
  GENERAL_USER: {
    id: 2 as SystemRoleTypeId,
    name: '一般利用者',
  },
  SYSTEM_ADMIN_USER: {
    id: 3 as SystemRoleTypeId,
    name: 'システム管理者',
  },
};

export const QUERY_STRING = {
  //共通して使用
  COMPANY_CODE: 'companyCode',
  OPEN_RANDOM_STRING: 'openRandomString',
  AUTH_FAILURE_TYPE: 'authFailureType',
  //工事設定のみ使用
  TYPE: 'type',
  //出来形管理のみ使用
  taskId: 'taskId',
};

//未認証 or 権限がないページ or 権限がない現場
export type AUTH_FAILURE_TYPE = 'unauthed' | 'unauthorized' | 'unauthorizedSite';

export const AUTH_FAILURE = {
  UNAUTHED: 'unauthed' as AUTH_FAILURE_TYPE,
  UNAUTHORIZED: 'unauthorized' as AUTH_FAILURE_TYPE,
  UNAUTHORIZED_SITE: 'unauthorizedSite' as AUTH_FAILURE_TYPE,
};

export const REQUEST_HEADERS = {
  AUTHORIZATION: 'Authorization',
  X_MIERUKOJI_COMPANY_CODE: 'X-Mierukoji-Company-Code',
  X_OPEN_RANDOM_STRING: 'X-Open-Random-String',
  X_SITELINK_API_USER_SCOPE: 'X-Sitelink-Api-User-Scope',
  X_SL_ACCESS_TOKEN: 'X-SL-Access-Token',
};

export const SWR_KEYS = {
  USER: 'user',
  USER_LIST: 'userList',
  SITE_LIST: 'siteList',
  SITE_LIST_ALL: 'siteListAll',
  SITE: 'site',
  SITE_WITH_SL: 'siteWithSl',
  DESIGN_SURFACE_LIST: 'designSurfaceList',
  FINISHED_SHAPE_DETAIL: 'finishedShapeDetail',
  FINISHED_SHAPE_LIST: 'finishedShapeList',
  FINISHED_SHAPE_REPORTS: 'finishedShapeReports',
  DATA_FILE: 'dataFile',
  DATA_FILE_LIST: 'dataFileList',
  DATA_FILE_LIST_QUERY: 'dataFileListQuery',
  DATA_FILE_LIST_QUERY_REFERENCE_POINT: 'dataFileListQueryReferencePoint',
  CALCULATED_ASBUILT_DATA_FILE_LIST: 'calculatedDataFileList',
  COMPANY_LIST: 'companyList',
  COMPANY: 'company',
  CONSTRUCTION_ZONE_LIST: 'constructionZoneList',
  SCHEDULE: 'schedule',
  SCHEDULE_WITH_QUERY: 'scheduleWithQuery',
  AS_BUILT: 'asBuilt',
  AS_BUILT_MAX_PAGE: 'asBuiltMaxPage',
  GROUND_SURVEY: 'groundSurvey',
  GROUND_SURVEY_MAX_PAGE: 'groundSurveyMaxPage',
  SOIL_AMOUNT: 'soilAmount',
  SENSOR_CAMERA: 'sensorCamera',
  SENSOR_TEMPERATURE: 'sensorTemperature',
  SOIL_AMOUNT_MEASUREMENT_ITEM: 'soilAmountMeasurementItem',
  SOIL_AMOUNT_TASKS_BY_DATE: 'soilAmountTasksByDate',
  SOIL_AMOUNT_TASKS_BY_TASK: 'soilAmountTasksByTask',
  SITE_LINK: {
    DESIGN_DATA_DOWNLOAD: 'designDataDownload',
  },
  RESOURCE_STATUS: {
    IN_PROGRESS: 'resourceStatusInProgress',
  },
};

export const LAS_DATA_OUTPUT_FILENAME = 'tileset.json';

export const DESIGN_DATA_OUTPUT_FILENAME = 'sample.geojson';

export const ORTHO_DATA_OUTPUT_FILENAME = 'tif_converted.png';

export const VALIDATION_MESSAGES = {
  REQUIRED: '必須項目です。',
  ACCESSIBLE_SITE_REQUIRED: '1件以上選択してください。',
  DATA_REGISTERED_DATE_REQUIRED: 'データが登録されている日付を選択する必要があります。',
  MEASUREMENT_ITEM_REQUIRED: '測定項目を選択する必要があります。',
  DESIGN_SURFACE_REQUIRED: '１つの測定項目につき、最低１つの設計面を選択する必要があります。',
  ALL_DESIGN_SURFACE_SELECTED: '全ての設計面が選択されている必要があります。',
  MEASUREMENT_ITEM_AND_DESIGN_SURFACE_REQUIRED: '測定項目と設計面を選択する必要があります。',
  INVALID_EMAIL: 'メールアドレスの形式が正しくありません。',
  INVALID_ORDER_YEAR: '発注年度は数値で入力する必要があります。',
  END_DATE_SAME_OR_AFTER: '終了予定日は開始予定日以降の日付である必要があります。',
  TASK_COMPLETE_DATE_SAME_OR_AFTER: '完了日は開始予定日以降の日付である必要があります。',
  KEIBANGOU_REQUIRED: '系番号を選択する必要があります。',
  IS_AS_BUILT_NOT_FOUND: '出来高データが存在しないため、進捗詳細をダウンロードできません。',
  YOUTUBE_URL_REQUIRED: 'YoutubeのURLを入力する必要があります。',
  NUMBER_REQUIRED: '数値を入力する必要があります。',
  URL_REQUIRED: 'URLを入力する必要があります。',
  GFF_FILE: 'GFFファイルを選択する必要があります。',
  TP3_OR_GC3_FILE: 'TP3またはGC3ファイルを選択する必要があります。',
  REQUIRED_LOCALIZATION_FILE: 'Localizationファイルを選択する必要があります。',
  MAX_EXPIRY_DATE: (max: number) => `有効期限は最大${max}日までです。`,
  MIN_LENGTH: (min: number) => `${min}文字以上で入力する必要があります。`,
  MAX_LENGTH: (max: number) => `${max}文字以下で入力する必要があります。`,
  CHECK_RANGE: (min: number, max: number) => `${min}から${max}の範囲内で入力する必要があります。`,
  MIN_NUMBER: (min: number) => `${min}以上の数値で入力する必要があります。`,
};

export const CESIUM_HAS_SEEN_NAV_HELP = 'cesium-hasSeenNavHelp';

export const SERVICE_NAME = {
  SITENOW: 'SiteNow',
  SITELINK: 'Sitelink3D',
};

export const JWT_SECRET = '123456' as string;

export const IDENTIFY_POOL_ID = process.env.NEXT_PUBLIC_IDENTIFY_POOL_ID || '';

export const S3_INPUT_BUCKET = process.env.NEXT_PUBLIC_S3_INPUT_BUCKET || '';
export const S3_BUCKET = process.env.NEXT_PUBLIC_S3_OUTPUT_BUCKET || '';
export const REGION = process.env.NEXT_PUBLIC_REGION || '';
export const COGNITO_USER_POOLS_ID = process.env.NEXT_PUBLIC_COGNITO_USER_POOLS_ID || '';
export const HOSTNAME = process.env.NEXT_PUBLIC_HOSTNAME || '';

export type envirment = 'staging' | 'production' | 'local' | 'develop';
export const CESIUM_TOKEN = process.env.NEXT_PUBLIC_CESIUM_TOKEN || '';
export const COGNITO_DUMMY_USERNAME = process.env.NEXT_PUBLIC_COGNITO_DUMMY_USERNAME || '';
export const COGNITO_DUMMY_PASSWORD = process.env.NEXT_PUBLIC_COGNITO_DUMMY_PASSWORD || '';
export const COGNITO_DUMMY_SYSADMIN_USERNAME =
  process.env.NEXT_PUBLIC_COGNITO_DUMMY_SYSADMIN_USERNAME || '';
export const COGNITO_DUMMY_SYSADMIN_PASSWORD =
  process.env.NEXT_PUBLIC_COGNITO_DUMMY_SYSADMIN_PASSWORD || '';
export const COGNITO_DUMMY_COMMON_USERNAME =
  process.env.NEXT_PUBLIC_COGNITO_DUMMY_COMMON_USERNAME || '';
export const COGNITO_DUMMY_COMMON_PASSWORD =
  process.env.NEXT_PUBLIC_COGNITO_DUMMY_COMMON_PASSWORD || '';

export const COMMIT_ID = process.env.NEXT_PUBLIC_COMMIT_ID || '';

export const SITELINK_AUTH_ORIGIN = process.env.NEXT_PUBLIC_SITELINK_AUTH_ORIGIN || '';
export const SITELINK_CLIENT_ID = process.env.NEXT_PUBLIC_SITELINK_CLIENT_ID || '';
export const SITELINK_REDIRECT_URI = process.env.NEXT_PUBLIC_SITELINK_REDIRECT_URI || '';
export const SITELINK_CLIENT_SECRET = process.env.SITELINK_CLIENT_SECRET || '';

//MEMO: 環境変数を取得する。コマンドで環境が変わるNODE_ENVはあまり使いたくないので独自で定義
export const CLIENT_ENV = process.env.NEXT_PUBLIC_CLIENT_ENV as envirment;

//MEMO ローカル環境用の会社コード。stg,prodではサブドメインを使用する想定
export const DEV_COMPANY_CODE = 'topcon';

export const API_URL_TOPCON = process.env.API_URL_TOPCON || 'http://localhost:3000';
export const PORT = process.env.PORT || 3002;
//WARN 有効期限が切れているトークン。使用すると、バックエンド側のverifyで弾かれる
export const DUMMY_AUTHORIZATION =
  'eyJraWQiOiJ0WVwvUnhnaXZaYUFJam9cL0N4ZDVIWmdzb3gzcG9uWTFPbkNhU045a0VTVG89IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJhZDE2MjBhMS1jMThmLTQzMjMtYjZlYi0yMWVlMTI4OTNmNmEiLCJjb2duaXRvOmdyb3VwcyI6WyJzZWlrb3RvbmUiXSwiZW1haWxfdmVyaWZpZWQiOnRydWUsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5hcC1ub3J0aGVhc3QtMS5hbWF6b25hd3MuY29tXC9hcC1ub3J0aGVhc3QtMV9FeEJET1E3OFoiLCJjb2duaXRvOnVzZXJuYW1lIjoidGVzdEBuY2RjLmNvLmpwIiwib3JpZ2luX2p0aSI6ImEwODYxZTNjLTkwOWQtNDM1Yy1iNWRmLWRkYjY4ODQ1ZjAwOCIsImNvZ25pdG86cm9sZXMiOlsiYXJuOmF3czppYW06OjA0MTE2MzcxOTYyODpyb2xlXC9taWVydWtvamktc3RnLXNlaWtvdG9uZS1zMy1yb2xlIl0sImF1ZCI6IjN2OXBkdHZwc2FibXVuOWxyNWt2bjlzNjJyIiwiZXZlbnRfaWQiOiJiOTJiMTlhMC1hZTA1LTRjYmYtOWIxNS1iZWNkYTY1MTkwNDkiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY0MTQyOTkwMCwiZXhwIjoxNjQxNDMzNTAwLCJpYXQiOjE2NDE0Mjk5MDAsImp0aSI6IjVkNmQxZDVhLTYxNWUtNDdmNS04ZDlmLTI4ODc0MTRjYjBlNyIsImVtYWlsIjoidGVzdEBuY2RjLmNvLmpwIn0=.NpRZCaC4nYdktxf9hvDXCKANYgCofa4WaPheGewB8_ILQrOmdtAUMAnpBMwMeDOCw6LOLRk_Ddt6al2x1lmBn4WM0CnLDAozRQHTPBslzv-VfuSZarNdLY5Uer6VFbZro2YiUPIFkguKIYu1DeKjkfCX9TJHXuTxpWO31ASPxqb7MvtYW4M5xkzNtUPhTDrxR2VpBXvdl0i0xmYLdYBr5_Vm-ddCPTd8grUAnxOChnfrrmHJPQq9cZ4d7hzGXjSQp2NMTIU8X6A1uNGLyiMm8FLIb34zrPXSSint9t0JMHzM5Ub9HCnanbIJAAgcjwL0nPK_nKpBeC9uMocGwgKJWQ';

export const theme: MantineThemeOverride = {
  fontFamily: 'Open Sans',
  primaryColor: 'primary',
  colors: {
    //MEMO 10色定義すると、prymaryShadeを指定する必要がなくなる。
    primary: [
      '#0064BE',
      '#0064BE',
      '#0064BE',
      '#0064BE',
      '#0064BE',
      '#0064BE',
      '#0064BE',
      //ボタンホバー時に使用される色
      '#8691A7',
      '#0064BE',
      '#0064BE',
    ],
    secondary: ['#E6EEFF'],
    whitesmoke: ['#F5F5F5'],
    rectangle: ['#E9E900', '#F2752D', '#629F3C', 'rgba(1, 9, 0, 0.5)'],
  },
};

//地理座標系（緯度経度）※GPSで使用されている座標系 https://lemulus.me/column/epsg-list-gis
export const COORDINATE_TO = 'EPSG:4326';

//Topconログイン用
export const TOPCON = 'topcon' as CognitoHostedUIIdentityProvider;

/**
 * バージョン
 * 元々はpackage.jsonから取得していたが、Amplifyでエラーが発生するので一旦変数で管理
 */
export const VERSION = '0.1.0';

export const PAGINATION_HEIGHT = 72;
export const CONTENTS_AREA_PADDING = 20;
