import Head from 'next/head';

import { useHeadMetaData } from '@/hooks/useHeadMetaData';
import { SERVICE_NAME } from '@/utils/constant';

export const CommonMeta = () => {
  const title = useHeadMetaData();

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={SERVICE_NAME.SITENOW} />
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      <link rel="shortcut icon" href="/favicon.ico" />
    </Head>
  );
};
