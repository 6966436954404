import { CognitoHostedUIIdentityProvider, CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';

import { OutputErrorLog } from '../error';

import awsConfigLocal from '@/aws-exports';
import awsConfigDev from '@/aws-exports_dev';
import awsConfigPrd from '@/aws-exports_prd';
import awsConfigStg from '@/aws-exports_stg';
import { SystemRoleTypeId } from '@/features/auth/types';
import {
  CLIENT_ENV,
  COGNITO_DUMMY_COMMON_PASSWORD,
  COGNITO_DUMMY_COMMON_USERNAME,
  COGNITO_DUMMY_PASSWORD,
  COGNITO_DUMMY_SYSADMIN_PASSWORD,
  COGNITO_DUMMY_SYSADMIN_USERNAME,
  COGNITO_DUMMY_USERNAME,
  SYSTEM_ROLE_ID,
} from '@/utils/constant';

const awsConfig = (() => {
  switch (CLIENT_ENV) {
    case 'local':
      return awsConfigLocal;
    case 'develop':
      return awsConfigDev;
    case 'staging':
      return awsConfigStg;
    case 'production':
      return awsConfigPrd;
    default:
      return awsConfigLocal;
  }
})();

// Assuming you have two redirect URIs, and the first is for localhost and second is for staging
const [
  redirectSignIn,
  //TODO: prod環境作成次第追加する
  //productionRedirectSignIn
] = awsConfig.oauth.redirectSignIn.split(',');
const [redirectSignOut] = awsConfig.oauth.redirectSignOut.split(',');

export const updatedAwsConfig = {
  ...awsConfig,
  //By providing ssr: true, Amplify persists credentials on the client in cookies so that subsequent requests to the server have access to them.
  //デフォルトではlocalStorageのみに保存するが、以下の設定でCookieにも保存する
  ssr: true,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn,
    redirectSignOut,
  },
};

//MEMO 属性の型定義
interface UserAttributes {
  email: string;
  familyName: string;
  givenName: string;
  systemRoleTypeId: number;
}
export interface CognitoUserExtension extends CognitoUser {
  attributes: UserAttributes;
}

export const signInToDummyUser = async () => {
  try {
    //hisamitsu@ncdc.co.jpに固定でログイン
    await Auth.signIn(COGNITO_DUMMY_USERNAME, COGNITO_DUMMY_PASSWORD);
    //現場一覧に遷移する
    window.location.href = 'sites?companyCode=test-company';
  } catch (e) {
    OutputErrorLog('signInToDummyUser', e);
  }
};

export const signInToDummySysAdminUser = async () => {
  try {
    //topcon-sitenow@ncdc.co.jpに固定でログイン
    await Auth.signIn(COGNITO_DUMMY_SYSADMIN_USERNAME, COGNITO_DUMMY_SYSADMIN_PASSWORD);
    //所有者に遷移する
    window.location.href = 'owner-users';
  } catch (e) {
    OutputErrorLog('signInToDummySysAdminUser', e);
  }
};
export const signInToDummyCommonUser = async () => {
  try {
    //tatsuya.otsuka@ncdc.co.jpに固定でログイン
    await Auth.signIn(COGNITO_DUMMY_COMMON_USERNAME, COGNITO_DUMMY_COMMON_PASSWORD);
    //現場一覧に遷移する
    window.location.href = 'sites?companyCode=test-company';
  } catch (e) {
    OutputErrorLog('signInToDummyCommonUser', e);
  }
};

export const signInForTestCompany = async () => {
  try {
    //https://github.com/aws-amplify/amplify-js/issues/3125
    Auth.federatedSignIn({
      provider: 'topcon' as CognitoHostedUIIdentityProvider,
      customState: JSON.stringify({ companyCode: 'test-company' }),
    });
  } catch (e) {
    OutputErrorLog('signInForTestCompany', e);
  }
};

//MEMO: 現状Google oidcサインインの実装となっているが、実際にはsitenowのoidcを使う
export const signIn = async (companyCode?: string | null) => {
  try {
    //Cookieを削除する
    document.cookie = 'slAccessToken=; max-age=0';
    //https://github.com/aws-amplify/amplify-js/issues/3125
    Auth.federatedSignIn({
      provider: 'topcon' as CognitoHostedUIIdentityProvider,
      customState: JSON.stringify({ companyCode }),
    });
  } catch (e) {
    OutputErrorLog('signIn', e);
  }
};

export const signOut = async (companyCode?: string | null) => {
  try {
    //loginページへcompanyCodeを引き継ぐ。速度改善のため先に呼び出す
    window.location.href = `/login?companyCode=${companyCode}`;
    await Auth.signOut({ global: true });
  } catch (e) {
    OutputErrorLog('signOut', e);
  }
};

const getRoleByCompanyCode = ({
  roles,
  companyCode,
}: {
  roles?: string[];
  companyCode: string | 'admin';
}): string => {
  if (!roles) {
    return '';
  }
  //adminの場合は、adminのロールを返す
  //admin以外の場合は、companyCodeに紐づくロールを返す。
  //companyCodeにひもづくロールがない場合は、0番目のロールを返す。
  const keyEnv = (() => {
    switch (CLIENT_ENV) {
      case 'develop':
      case 'local':
        return 'dev';
      case 'production':
        return 'prd';
      case 'staging':
        return 'stg';
      default:
        return 'dev';
    }
  })();
  const key = `topcon-sitenow-${keyEnv}-${companyCode}-s3-role`;
  const role = roles.find((item) => item.includes(key));
  return role || roles[0];
};

export const getUserSession = async ({
  isSubstitute,
  systemRoleTypeId,
  companyCode,
}: {
  isSubstitute?: boolean;
  systemRoleTypeId: SystemRoleTypeId;
  companyCode: string;
}) => {
  try {
    //未認証ユーザーの場合、idTokenとcustomRoleArnは空文字になる。
    //そうするとゲストユーザーとしてIDPに認証される。
    if (systemRoleTypeId === SYSTEM_ROLE_ID.UNAUTHENTICATED_USER.id) {
      return {
        idToken: '',
        customRoleArn: '',
      };
    }
    const roles = (await Auth.currentSession())?.getIdToken()?.payload['cognito:roles'];
    const idToken = (await Auth.currentSession())?.getIdToken()?.getJwtToken() || '';
    //isSubstituteがtrueの場合は、adminのロールを返す。それ以外の場合は、companyCodeに紐づくロールを返す。
    const customRoleArn = getRoleByCompanyCode({
      roles,
      companyCode: isSubstitute ? 'admin' : companyCode || '',
    });
    return { idToken, customRoleArn };
  } catch (e) {
    OutputErrorLog('getUserSession', e);
    return null;
  }
};
