import { OutputErrorLog } from '@/lib/error';

export const getIdTokenFromCookies = (cookies: { [x: string]: string }) => {
  try {
    const keys = Object.keys(cookies);
    const findTargetKey = keys.find((key) => {
      const splittedKey = key.split('.');
      const lastStr = splittedKey[splittedKey.length - 1];
      return lastStr === 'idToken';
    }) as string;
    return cookies[findTargetKey];
  } catch (e) {
    OutputErrorLog('getIdTokenFromCookies', e);
    return null;
  }
};
