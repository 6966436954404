import { ConstructionZone } from '../types';

import { axios } from '@/lib/axios';
import { OutputErrorLog } from '@/lib/error';
import { GenerateAPIHeaderRequest, generateAPIHeader } from '@/utils/generateAPIHeader';

//工区取得API
export const getConstructionZones = async ({
  siteId,
  headers,
}: {
  siteId: number;
  headers?: GenerateAPIHeaderRequest;
  // eslint-disable-next-line consistent-return
}): Promise<ConstructionZone[]> => {
  try {
    const apiHeaders = generateAPIHeader(headers);
    const result = await axios.get<ConstructionZone[]>(
      `/sites/${siteId}/construction-zones`,
      apiHeaders
    );
    return result.data;
  } catch (error) {
    OutputErrorLog('getConstructionZones', error);
    return [];
  }
};
