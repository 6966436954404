import {
  DataFileProcessType,
  ProcessStatus,
  ResourceStatus,
  ResourceType,
} from '@/features/polling/hooks/usePolling';
import { DataFile, DataFileType } from '@/features/siteTop/types';
import { SERVICE_NAME } from '@/utils/constant';

export const getNotificationDetail = (
  resourceStatus: ResourceStatus,
  options: {
    resourceName: string | null;
    prevResourceStatus?: ResourceStatus;
    dataFiles: DataFile[];
  }
) => {
  const { resourceName, prevResourceStatus, dataFiles } = options;
  const result = {
    title: '',
    message: '',
    color: 'primary',
    autoClose: true,
    loading: false,
  };
  const targetDataFile = dataFiles.find((dataFile) => dataFile.id === resourceStatus.resourceId);
  const isLightDataFile =
    targetDataFile?.typeId === DataFileType.GROUND_SURVEY_LIGHT ||
    targetDataFile?.typeId === DataFileType.AS_BUILT_MERGED;
  switch (resourceStatus.resourceType) {
    case ResourceType.DataFile:
      switch (resourceStatus.processStatus) {
        case ProcessStatus.IN_PROGRESS:
          switch (resourceStatus.processType) {
            case DataFileProcessType.SlSync:
              result.title = '連携中';
              result.message = resourceName
                ? `${resourceName} ファイルの${SERVICE_NAME.SITELINK}連携を行っています`
                : `ファイルの${SERVICE_NAME.SITELINK}連携を行っています`;
              result.autoClose = false;
              result.loading = true;
              break;
            case DataFileProcessType.Transfer:
              result.title = '変換中';

              result.message = resourceName
                ? `${resourceName} ファイルの変換を行っています`
                : `ファイルの変換を行っています`;
              result.autoClose = false;
              result.loading = true;
              break;
          }
          break;
        case ProcessStatus.DELETE_IN_PROGRESS:
          switch (resourceStatus.processType) {
            case DataFileProcessType.SlSync:
              result.title = '削除中';
              result.message = resourceName
                ? `${resourceName} ファイルの削除を行っています`
                : `ファイルの削除を行っています`;
              result.autoClose = false;
              result.loading = true;
              break;
            case DataFileProcessType.Transfer:
              //このパターンはありえないので、何もしない
              break;
          }
          break;
        case ProcessStatus.FAILED:
          switch (resourceStatus.processType) {
            case DataFileProcessType.SlSync:
              result.title = '連携失敗';
              result.message = resourceName
                ? `${resourceName} ファイルの${SERVICE_NAME.SITELINK}連携に失敗しました`
                : `ファイルの${SERVICE_NAME.SITELINK}連携に失敗しました`;
              result.color = 'red';
              break;
            case DataFileProcessType.Transfer:
              result.title = '変換失敗';
              result.message = resourceName
                ? `${resourceName} ファイルの変換に失敗しました`
                : `ファイルの変換に失敗しました`;
              result.color = 'red';
              break;
          }
          break;
        case ProcessStatus.SUCCESS:
          switch (resourceStatus.processType) {
            case DataFileProcessType.SlSync:
              switch (prevResourceStatus?.processStatus) {
                case ProcessStatus.DELETE_IN_PROGRESS:
                  result.title = '削除成功';
                  result.message = resourceName
                    ? `${resourceName} ファイルの削除が完了しました`
                    : `ファイルの削除が完了しました`;
                  break;
                case ProcessStatus.IN_PROGRESS:
                  result.title = '連携成功';
                  result.message = resourceName
                    ? `${resourceName} ファイルの${SERVICE_NAME.SITELINK}連携が完了しました`
                    : `ファイルの${SERVICE_NAME.SITELINK}連携が完了しました`;
                  break;
              }

              break;
            case DataFileProcessType.Transfer:
              result.title = '変換成功';
              result.message = resourceName
                ? `${resourceName} ファイルの変換が完了しました`
                : `ファイルの変換が完了しました`;

              break;
          }
          break;
      }
      break;
    case ResourceType.FinishedShape:
      switch (resourceStatus.processStatus) {
        case ProcessStatus.IN_PROGRESS:
          result.title = '作成中';
          result.message = resourceName
            ? `${resourceName} 作業の帳票作成を行っています`
            : `帳票の作成を行っています`;
          result.autoClose = false;
          result.loading = true;
          break;

        case ProcessStatus.FAILED:
          result.title = '作成失敗';
          result.message = resourceName
            ? `${resourceName} 作業の帳票作成に失敗しました。`
            : '帳票の作成に失敗しました。';
          result.color = 'red';
          break;
        case ProcessStatus.SUCCESS:
          result.title = '作成成功';
          result.message = resourceName
            ? `${resourceName} 作業の帳票作成が完了しました。`
            : `帳票の作成が完了しました。`;
          break;
      }
      break;
    case ResourceType.Site:
      switch (resourceStatus.processStatus) {
        case ProcessStatus.IN_PROGRESS:
          result.title = '移動中';
          result.message = `工事のファイルを移動しています`;
          result.autoClose = false;
          result.loading = true;
          break;

        case ProcessStatus.FAILED:
          result.title = '移動失敗';
          result.message = `工事のファイルの移動に失敗しました。`;
          result.color = 'red';
          break;
        case ProcessStatus.SUCCESS:
          result.title = '移動成功';
          result.message = `工事のファイルの移動が完了しました。`;
          break;
      }
      break;
    case ResourceType.HeightMapReport:
      switch (resourceStatus.processStatus) {
        case ProcessStatus.IN_PROGRESS:
          result.title = '受信中';
          result.message = `施工履歴データを受信しています`;
          result.autoClose = false;
          result.loading = true;
          break;

        case ProcessStatus.FAILED:
          result.title = '受信失敗';
          result.message = `施工履歴データの受信に失敗しました。`;
          result.color = 'red';
          break;
        case ProcessStatus.SUCCESS:
          result.title = '受信成功';
          result.message = `施工履歴データの受信が完了しました。`;
          break;
      }
      break;
    case ResourceType.SoilAmountCalculation:
      switch (resourceStatus.processStatus) {
        case ProcessStatus.IN_PROGRESS:
          result.title = '土量計算中';
          result.message = `土量の計算を行っています。`;
          result.autoClose = false;
          result.loading = true;
          break;
        case ProcessStatus.SUCCESS:
          result.title = '土量計算完了';
          result.message = `土量の計算が完了しました。`;
          break;
      }
      break;
  }
  return result;
};
