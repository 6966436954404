import { DEFAULT_COLUMN_ORDER } from '../utils/constant';

import { Task } from '@/features/saveSite/types';
import { BaseEntity } from '@/types';

export type FileExt =
  | 'xml'
  | 'tp3'
  | 'tn3'
  | 'las'
  | 'csv'
  | 'txt'
  | 'xyz'
  | 'sim'
  | 'tif'
  | 'tiff'
  | 'gc3'
  | 'gff'
  //それぞれの大文字
  | 'XML'
  | 'TP3'
  | 'TN3'
  | 'LAS'
  | 'CSV'
  | 'TXT'
  | 'XYZ'
  | 'SIM'
  | 'TIF'
  | 'TIFF'
  | 'GC3'
  | 'GFF';

export type DataFile = {
  siteId: number;
  typeId: DataFileType;
  transferStatusCode: TransferStatusCode;
  fileName: string;
  fileExt: FileExt;
  pointName: ColumnOrder;
  x: ColumnOrder;
  y: ColumnOrder;

  size?: string;
  date: ColumnOrder | null;

  source: DataFileSource;
  originalDataFileId?: number;
  slSyncStatus?: SlSyncStatus;
  slTransferId?: string;
  slFileId?: string;
  z: ColumnOrder | null;
  light: ColumnOrder | null;
  r: ColumnOrder | null;
  g: ColumnOrder | null;
  b: ColumnOrder | null;
  shouldExcludeFirstRow: boolean;
  measurementDate?: string;
  taskIdList?: number[];
  error?: DataFileError;
  isIntegrated?: boolean;
  recruitmentCriteriaId?: RecruitmentCriteria;
  order?: number;
  isExcluded?: boolean;
} & BaseEntity;
export type DataFileError = {
  column?: string;
  line?: number;
  message?: string;
  dataFileId?: number;
} & BaseEntity;
export type DataFileEntity = {
  tasks?: Task[];
} & BaseEntity &
  Exclude<DataFile, 'taskIdList'>;

export enum TransferStatusCode {
  /** 初期値（登録） */
  INITIAL = 1,
  /** バリデーション中（バリデーション依頼済み） */
  VALIDATING = 4,
  /** バリデーション成功 */
  VALIDATED = 5,
  /** フィルタリング中（フィルタ処理依頼済み） */
  FILTERING = 6,
  /** フィルタリング成功 */
  FILTERED = 7,
  /** 変換中（変換依頼済み） */
  SUBMITTED = 2,
  /** 変換済み */
  SUCCESS = 3,
  /** バリデーション失敗 */
  VALIDATION_FAILED = -3,
  /** フィルタリング失敗 */
  FILTER_FAILED = -4,
  /** 変換失敗（データ・入力不備） */
  READ_DATA_FAILED = -1,
  /** 変換失敗（システムエラー） */
  SERVER_ERROR = -2,
}
export enum DataFileType {
  /** 基準点データ*/
  REFERENCE_POINT = 1,
  /** オルソ画像 */
  ORTHO = 2,
  /** 設計データ */
  DESIGN = 3,
  /** 起工測量データ */
  GROUND_SURVEY = 4,
  /** 出来高データ */
  AS_BUILT = 5,
  /** 出来高データ（統合済） */
  AS_BUILT_MERGED = 6,
  /** 起工測量データ（TIN形式） */
  GROUND_SURVEY_TIN = 7,
  /** 起工測量データ（軽量化） */
  GROUND_SURVEY_LIGHT = 8,
}

export type ColumnOrder = 0 | 1 | 2 | 3 | 4 | 5 | 6;

//2443 - 2461 &  6669 - 6687
export type Coordinate =
  | 2443
  | 2444
  | 2445
  | 2446
  | 2447
  | 2448
  | 2449
  | 2450
  | 2451
  | 2452
  | 2453
  | 2454
  | 2455
  | 2456
  | 2457
  | 2458
  | 2459
  | 2460
  | 2461
  | 6669
  | 6670
  | 6671
  | 6672
  | 6673
  | 6674
  | 6675
  | 6676
  | 6677
  | 6678
  | 6679
  | 6680
  | 6681
  | 6682
  | 6683
  | 6684
  | 6685
  | 6686
  | 6687;

export type AsBuiltData = {
  points: Point[];
  id: string;
  siteId: number;
  dataFileId: number;
  nextPage: number | null;
};

export type Point = {
  x: number;
  y: number;
  z: number;
  r: number;
  g: number;
  b: number;
};

export type ColumnOption = { value: string; label: string }[];

export type Column =
  | (typeof DEFAULT_COLUMN_ORDER.REFERENCE_POINT_DATA)[number]
  | (typeof DEFAULT_COLUMN_ORDER.POINT_CLOUD_DATA)[number];

export type DefaultColumnOrder =
  | typeof DEFAULT_COLUMN_ORDER.REFERENCE_POINT_DATA
  | typeof DEFAULT_COLUMN_ORDER.POINT_CLOUD_DATA;
export enum DataFileSource {
  SiteNow = 'SN',
  Sitelink3D = 'SL',
  Raptor = 'Raptor',
}
export enum RecruitmentCriteria {
  /** 最下点 */
  LOWEST_POINT = 1,
  /** 新しい計測点 */
  NEW_MEASUREMENT_POINT = 2,
  /*元の計測点 */
  ORIGINAL_MEASUREMENT_POINT = 3,
  /**追加の計測点 */
  ADDITIONAL_MEASUREMENT_POINT = 4,
}

export enum SlSyncStatus {
  /** 初期値（未連携） */
  INITIAL = 'INITIAL',
  /** 同期中 */
  SYNC_IN_PROGRESS = 'SYNC_IN_PROGRESS',
  /** 同期成功 */
  SYNC_SUCCESS = 'SYNC_SUCCESS',
  /** 同期失敗 */
  SYNC_FAILED = 'SYNC_FAILED',
  /** 削除中 */
  DELETE_IN_PROGRESS = 'DELETE_IN_PROGRESS',
  /** 削除失敗 */
  DELETE_FAILED = 'DELETE_FAILED',
  /** 同期失敗（システムエラー） */
  SERVER_ERROR = 'SERVER_ERROR',
}

export enum DataFileOrder {
  MIN = 1,
}

export type MachineResponseBasic = Partial<{
  asset_class: string;
  machine: Partial<{
    asset_class: string;
    name: string;
    oem: string;
    type: string;
  }>;
  urn: string;
}>;

export enum MachineResponseType {
  WGS = 'wgs',
  LOCAL = 'local',
}

export type MachineResponsePosition = Partial<{
  altitude: number;
  at: number;
  latitude: number;
  longitude: number;
  urn: string;
  northing: number;
  easting: number;
  elevation: number;
  type: MachineResponseType;
}>;

export type SoilAmount = {
  cutSoil: number;
  totalCutSoil: number;
  fillSoil: number;
  totalFillSoil: number;
};

export type DisplayedSoilAmount = {
  id: string;
  siteId: number;
  measurementItemId: number;
  processingShapeId: number;
  heightDiffList: HeightDiffList[];
  nextPage: number | null;
  meshSize?: number;
};

export type HeightDiffList = {
  x: number;
  y: number;
  cadHeightMin?: number;
  cadHeightMax?: number;
  cadHeightMedian?: number;
  heightDiffMin?: number;
  heightDiffMax?: number;
  heightDiffMedian?: number;
};
