import { DataFile, DataFileEntity } from '../types';

export const convertDataFileTaskIdList = (dataFile: DataFileEntity): DataFile => {
  const taskIdList = dataFile.tasks?.map((task) => task.id) ?? [];
  return {
    ...dataFile,
    taskIdList,
  };
};

export const convertDataFileListTaskIdList = (dataFileList: DataFileEntity[]): DataFile[] =>
  dataFileList.map((dataFile) => convertDataFileTaskIdList(dataFile));
