import { ResourceStatus } from '@/features/polling/hooks/usePolling';
import { axios } from '@/lib/axios';
import { OutputErrorLog } from '@/lib/error';

export const getResourceStatus = async (siteId: number): Promise<ResourceStatus[]> => {
  try {
    const result = await axios.get<ResourceStatus[]>(
      `/sites/${siteId}/resource-status/in-progress`
    );
    return result.data;
  } catch (error) {
    OutputErrorLog('getResourceStatus', error);
    return [];
  }
};
