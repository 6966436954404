import { Color } from 'cesium';
import dayjs from 'dayjs';
import { atom } from 'recoil';

import { SensorCamera, SensorTemperature } from '../api/sensor';
import { ParsedSiteLinkMachineResponse } from '../api/slMachine';
import { DataFileSchemaType } from '../components/Data3dRegistration/UploadDataFile';
import { SensorMenuType } from '../hooks/useSensorMenu';
import { generateDefaultDataByDataFileType } from '../lib/generateDefaultData';
import { DataFile, DataFileType } from '../types';

import { DESIGN_FILE_EXTENSIONS } from '@/utils/constant';

//入力途中の場合もあるので、DataFileSchemaTypeの型をそのまま使う

export type DataFileState = {
  referencePointData: {
    isExist: boolean;
    data: DataFileSchemaType;
  };
  orthoData: {
    isExist: boolean;
    data: DataFileSchemaType;
  };
  //desingDataは、isExsitがFALSEのobjは発生しない想定
  designData: {
    isExist: boolean;
    data: DataFileSchemaType;
  }[];
  groundSurveyData: {
    isExist: boolean;
    data: DataFileSchemaType;
  };
  asBuiltData: {
    isExist: boolean;
    data: DataFileSchemaType;
  };
};

//3d画面に表示する対象となるデータファイルを管理する。土量系は別で管理
export const displayedDataFileState = atom<DataFileState>({
  key: 'displayedDataFile',
  default: {
    referencePointData: {
      isExist: false,
      data: generateDefaultDataByDataFileType(DataFileType.REFERENCE_POINT),
    },
    orthoData: {
      isExist: false,
      data: generateDefaultDataByDataFileType(DataFileType.ORTHO),
    },
    designData: [],
    groundSurveyData: {
      isExist: false,
      data: generateDefaultDataByDataFileType(DataFileType.GROUND_SURVEY, {
        measurementDate: dayjs().format('YYYY-MM-DD'),
      }),
    },
    asBuiltData: {
      isExist: false,
      data: generateDefaultDataByDataFileType(DataFileType.AS_BUILT, {
        measurementDate: dayjs().format('YYYY-MM-DD'),
      }),
    },
  },
});

export type optionSetting = {
  pointSize: number;
  meshSize: number;
  heightColor: number;
};

//3d画面に表示する対象となるデータファイルを管理する
export const optionSettingState = atom<optionSetting>({
  key: 'optionSetting',
  default: {
    pointSize: 3,
    meshSize: 1,
    heightColor: 5,
  },
});

export const uploadingDataFilesState = atom<DataFile[]>({
  key: 'uploadingDataFiles',
  default: [],
});

export const cesiumToolbarVisibleState = atom<boolean>({
  key: 'cesiumToolbarVisible',
  default: false,
});

export type SelectedDesignSurface = {
  designSurfaceId: number | null;
  color: Color;
};

export const selectedDesignSurfacesState = atom<SelectedDesignSurface[]>({
  key: 'selectedDesignSurfaces',
  default: [],
});
export type CesiumMenu = 'setting' | SensorMenuType | null;
export const settingMenuState = atom<CesiumMenu>({
  key: 'settingMenu',
  default: 'setting',
});

export type SetSensorPosition = {
  x: number | null;
  y: number | null;
  mode: SensorMenuType | null;
};

export const setSensorPositionState = atom<SetSensorPosition>({
  key: 'setSensorPosition',
  default: { x: null, y: null, mode: null },
});

export type DisplaySensorCameraPosition = (Partial<SensorCamera> & { clickedDateTime: number })[];

//動画の表示位置
export const displaySensorCameraPositionState = atom<DisplaySensorCameraPosition>({
  key: 'displaySensorCameraPosition',
  default: [],
});

//表示する動画管理
export const displaySensorCameraState = atom<SensorCamera[]>({
  key: 'displaySensorCamera',
  default: [],
});

//表示する温度管理
export const displaySensorTemperatureState = atom<SensorTemperature[]>({
  key: 'displaySensorTemperature',
  default: [],
});

//全ての建機
export const parsedMachineState = atom<ParsedSiteLinkMachineResponse[]>({
  key: 'parsedMachine',
  default: [],
});

//表示する建機管理
export const displayMachineState = atom<ParsedSiteLinkMachineResponse[]>({
  key: 'displayMachine',
  default: [],
});

export type DesignDataFilterStateOrderBy = 'createdAt' | 'fileName';
export type DesignDataFilterStateOrder = 'ASC' | 'DESC';

export type DesignDataFilterState = {
  typeId?: DataFileType;
  fileName?: string;
  fileExt?: string[];
  orderBy?: DesignDataFilterStateOrderBy;
  order?: DesignDataFilterStateOrder;
};

export const designDataFilterState = atom<DesignDataFilterState>({
  key: 'designDataFilter',
  default: {
    fileName: '',
    orderBy: 'createdAt',
    order: 'DESC',
    fileExt: DESIGN_FILE_EXTENSIONS.lower,
  },
});

export const inProgressAsBuiltDataState = atom<boolean>({
  key: 'inProgressAsBuiltData',
  default: false,
});
