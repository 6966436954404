import { FC } from 'react';

// eslint-disable-next-line no-restricted-imports
import Link from 'next/link';

import { AuthorizePagination, useAuthorizeRouter } from '@/features/auth/hooks/useAuthorizeRouter';

type AuthorizeLink = AuthorizePagination & {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  invalid?: boolean;
};

export const AuthorizeLink: FC<AuthorizeLink> = ({ invalid = false, children, ...props }) => {
  const router = useAuthorizeRouter();
  //openRandomStringとcompanyCodeの存在するqueryを保持する
  const defaultQueryOptions = {
    openRandomString: router.query.openRandomString as string | undefined,
    companyCode: router.query.companyCode as string | undefined,
  };
  const getQuery = () => {
    const { defaultQuery, addQuery, excludeQuery } = props.options || {};
    const query = {
      ...defaultQueryOptions,
      ...defaultQuery,
      ...addQuery,
    };
    excludeQuery?.forEach((key) => {
      //@ts-ignore
      delete query[key];
    });
    //値が存在しないqueryは削除する
    Object.keys(query).forEach((key) => {
      //@ts-ignore
      if (!query[key]) {
        //@ts-ignore
        delete query[key];
      }
    });
    return query;
  };
  if (invalid) {
    return (
      <span className={props.className} style={props.style}>
        {children}
      </span>
    );
  }

  return (
    <Link
      className={props.className}
      href={{ pathname: props.pathname, query: getQuery() }}
      style={props.style}
    >
      {children}
    </Link>
  );
};
