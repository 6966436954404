import Axios, { AxiosRequestConfig } from 'axios';

import { getUserSession } from './auth/auth';
import { OutputErrorLog } from './error';

import { HOSTNAME } from '@/utils/constant';

async function authRequestInterceptor(config: AxiosRequestConfig): Promise<any> {
  if (!config.headers) return config;
  // eslint-disable-next-line no-param-reassign
  config.baseURL = `${HOSTNAME}/api/bff`;
  //config.baseURL = '/api/bff';
  if (typeof window === 'undefined') {
    //サーバーサイド

    const token = config.headers.Authorization || '';
    let Authorization = token ? `Bearer ${token}` : '';
    const openRandomString = config.headers['X-Open-Random-String'];
    //openRandomStringとAuthorization(token)の両方が存在する場合、Authorizationを送信しない。未認証ユーザーとして扱う
    if (openRandomString && token) {
      Authorization = '';
    }
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      Authorization,
    };
    //サーバーサイド実行時にはCookieから取得したidTokenを渡す
  } else {
    try {
      //ブラウザ側
      //queryからcompanyCodeとopenRandomStringを取得する
      const companyCode = new URLSearchParams(window.location.search).get('companyCode');
      const openRandomString = new URLSearchParams(window.location.search).get('openRandomString');
      //openRandomStringが存在する場合は、idTokenを空文字にする(ログインされていないので)
      //isSubstituteとsystemRoleTypeIdは適当。idToken取得には関係ない
      const userSession = openRandomString
        ? {
            idToken: '',
          }
        : await getUserSession({
            isSubstitute: false,
            systemRoleTypeId: 1,
            companyCode: companyCode || '',
          });

      const token = userSession?.idToken || '';
      let Authorization = token ? `Bearer ${token}` : '';
      //openRandomStringとAuthorization(token)の両方が存在する場合、Authorizationを送信しない。未認証ユーザーとして扱う
      if (openRandomString && token) {
        Authorization = '';
      }
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        ...config.headers,
        //useAuthenticator Hookから取得したい。なさそう。
        Authorization,
        'X-Mierukoji-Company-Code': companyCode || '',
        'X-Open-Random-String': openRandomString || '',
      };
    } catch (error) {
      OutputErrorLog('authRequestInterceptor', error);
    }
  }
  // eslint-disable-next-line no-param-reassign
  config.headers.Accept = 'application/json';
  return config;
}

export const axios = Axios.create();

axios.interceptors.request.use(authRequestInterceptor);
