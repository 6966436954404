import { FC } from 'react';

import { MainLayout } from './MainLayout';

import { useAuthorizeRouter } from '@/features/auth/hooks/useAuthorizeRouter';
import { PAGE_PATHS } from '@/utils/constant';

type LayoutProps = {
  children: React.ReactNode;
};

export const Layout: FC<LayoutProps> = ({ children }) => {
  const router = useAuthorizeRouter();
  const path = router.pathname;
  if (path === PAGE_PATHS.LOGIN || path === PAGE_PATHS.TOP) {
    return <>{children}</>;
  }
  return <MainLayout>{children}</MainLayout>;
};
