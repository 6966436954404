import { ColorScheme } from '@mantine/core';
import { Amplify, Hub } from 'aws-amplify';
import NextApp, { AppContext, AppProps } from 'next/app';

import { updatedAwsConfig } from '@/lib/auth/auth';
import { listener } from '@/lib/auth/hub-listener';
import { OutputErrorLog } from '@/lib/error';
import { getInitialData } from '@/lib/getIntialData';
import { AppProvider } from '@/providers/app';
import '@/styles/globals.css';
import { PAGE_PATHS } from '@/utils/constant';
import { getIdTokenFromCookies } from '@/utils/getIdTokenFromCookies';
import 'cesium/Build/Cesium/Widgets/widgets.css';

Amplify.configure(updatedAwsConfig);
Hub.listen('auth', listener);
if (typeof window !== 'undefined') {
  // @ts-ignore
  window.CESIUM_BASE_URL = '/cesium';
}
export default function App(
  props: AppProps & {
    colorScheme: ColorScheme;
    fallback: {
      [x: string]: any;
    };
  }
) {
  const { Component, pageProps, fallback } = props;

  return (
    <AppProvider fallback={fallback}>
      <Component {...pageProps} />
    </AppProvider>
  );
}

export type ExtendedAppContext = {
  ctx: {
    req: {
      cookies?: {
        [x: string]: string;
      };
    };
    query: {
      siteId?: string | number;
      userId?: string | number;
      companyId?: string | number;
      finishedShapeId?: string | number;
    };
  };
} & AppContext;

//MEMO getServerSirePropsから呼び出したBFFサーバでCookieを取得できない。
//Clientサイドから呼び出した場合は取得できる。原因はおそらく、baseURLを変えているから。現状は解決策わからない。

App.getInitialProps = async (appContext: ExtendedAppContext) => {
  try {
    //ログインとIndexでは何もしない
    if (
      appContext.router.pathname === PAGE_PATHS.TOP ||
      appContext.router.pathname === PAGE_PATHS.LOGIN ||
      appContext.router.pathname === PAGE_PATHS.SITELINK
    ) {
      return {
        fallback: {},
      };
    }
    const appProps = await NextApp.getInitialProps(appContext);
    const { req } = appContext.ctx;
    if (!req?.cookies)
      return {
        ...appProps,
        fallback: {
          siteList: [],
        },
      };
    const idToken = getIdTokenFromCookies(req.cookies);
    if (!idToken) {
      return {
        ...appProps,
        fallback: {
          siteList: [],
        },
      };
    }
    const fallback = await getInitialData(appContext, idToken);
    return {
      ...appProps,
      fallback,
    };
  } catch (error) {
    OutputErrorLog('getinitialProps', error);
    return {
      fallback: {},
    };
  }
};
