import * as React from 'react';

import useSWR from 'swr';

import { getUser } from '@/features/auth/api/getUser';
import { MeUser } from '@/features/auth/types';
import { SWR_KEYS } from '@/utils/constant';

type AuthorizationProps = {
  forbiddenFallback?: React.ReactNode;
  children: React.ReactNode;
  allowedRoles?: MeUser['systemRoleTypeId'][];
  ignore?: boolean;
};

export const Authorization = ({
  allowedRoles,
  forbiddenFallback = null,
  children,
  ignore = false,
}: AuthorizationProps) => {
  if (ignore) return <>{children}</>;
  const { data: user } = useSWR(SWR_KEYS, () => getUser());
  if (!user) return null;
  let canAccess = false;

  if (allowedRoles) {
    canAccess = allowedRoles.includes(user?.systemRoleTypeId);
  }

  return <>{canAccess ? children : forbiddenFallback}</>;
};
