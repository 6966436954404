import { useResetRecoilState } from 'recoil';
import { useSWRConfig } from 'swr';

import {
  cesiumToolbarVisibleState,
  displayMachineState,
  displaySensorCameraPositionState,
  displaySensorCameraState,
  displaySensorTemperatureState,
  displayedDataFileState,
  optionSettingState,
  parsedMachineState,
  setSensorPositionState,
  uploadingDataFilesState,
} from '@/features/siteTop/store/atom';
import { OutputErrorLog } from '@/lib/error';
import { prevResourceStatusDataState } from '@/store/atom';
import { SWR_KEYS } from '@/utils/constant';

export const useDeleteCache = () => {
  const { mutate } = useSWRConfig();
  const resetDisplayedDataFileState = useResetRecoilState(displayedDataFileState);

  const resetOptionSettingState = useResetRecoilState(optionSettingState);
  const resetUploadingDataFilesState = useResetRecoilState(uploadingDataFilesState);
  const resetCesiumToolbarVisibleState = useResetRecoilState(cesiumToolbarVisibleState);

  const resetSetSensorPositionState = useResetRecoilState(setSensorPositionState);
  const resetDisplaySensorCameraPositionState = useResetRecoilState(
    displaySensorCameraPositionState
  );
  const resetDisplaySensorCameraState = useResetRecoilState(displaySensorCameraState);
  const resetDisplaySensorTemperatureState = useResetRecoilState(displaySensorTemperatureState);
  const resetDisplayMachineState = useResetRecoilState(displayMachineState);
  const resetParsedMachineState = useResetRecoilState(parsedMachineState);
  const resetPrevResourceStatusDataState = useResetRecoilState(prevResourceStatusDataState);
  const deleteCache = (siteId: number) => {
    try {
      //当該ページにアクセスされた際には、現場依存のキャッシュを削除する
      //https://zenn.dev/uttk/scraps/10dbab958a1
      mutate([SWR_KEYS.SITE_LIST, siteId], undefined);
      mutate([SWR_KEYS.SITE, siteId], undefined);
      mutate([SWR_KEYS.CONSTRUCTION_ZONE_LIST, siteId], undefined);
      mutate([SWR_KEYS.SCHEDULE, siteId], undefined);
      mutate([SWR_KEYS.DATA_FILE_LIST, siteId], undefined);
      mutate([SWR_KEYS.DATA_FILE_LIST_QUERY_REFERENCE_POINT, siteId], undefined);
      mutate([SWR_KEYS.FINISHED_SHAPE_LIST, siteId], undefined);
      mutate([SWR_KEYS.FINISHED_SHAPE_DETAIL, siteId], undefined);
      mutate([SWR_KEYS.RESOURCE_STATUS.IN_PROGRESS, siteId], undefined);
      resetDisplayedDataFileState();
      resetPrevResourceStatusDataState();
      resetOptionSettingState();
      resetUploadingDataFilesState();
      resetCesiumToolbarVisibleState();

      resetSetSensorPositionState();
      resetDisplaySensorCameraPositionState();
      resetDisplaySensorCameraState();
      resetDisplaySensorTemperatureState();
      resetDisplayMachineState();
      resetParsedMachineState();
    } catch (e) {
      OutputErrorLog('deleteCache', e);
    }
  };
  return { deleteCache };
};
