//データファイル取得API

import { convertDataFileTaskIdList } from '../lib/convertDataFile';
import { DataFile, DataFileEntity } from '../types';

import { axios } from '@/lib/axios';
import { OutputErrorLog } from '@/lib/error';

export const getDataFile = async ({
  siteId,
  dataFileId,
  token,
}: {
  siteId: number;
  dataFileId: number;
  token?: string;
  // eslint-disable-next-line consistent-return
}): Promise<DataFile | null> => {
  try {
    const result = await axios.get<DataFileEntity>(`/sites/${siteId}/data-file/${dataFileId}`);
    return convertDataFileTaskIdList(result.data);
  } catch (error) {
    OutputErrorLog('getDataFile', error);
    return null;
  }
};
