import { FinishedShapeListResponseDto } from '../types';

import { axios } from '@/lib/axios';
import { OutputErrorLog } from '@/lib/error';
import { GenerateAPIHeaderRequest, generateAPIHeader } from '@/utils/generateAPIHeader';

export const getFinishedShapeList = async ({
  siteId,
  headers,
}: {
  siteId: number;
  headers?: GenerateAPIHeaderRequest;
}): Promise<FinishedShapeListResponseDto[]> => {
  try {
    const apiHeaders = generateAPIHeader(headers);
    const result = await axios.get<FinishedShapeListResponseDto[]>(
      `/sites/${siteId}/finished-shapes`,
      apiHeaders
    );
    return result.data;
  } catch (error) {
    OutputErrorLog('getTaskAndFinishedShapeList', error);
    return [];
  }
};
