import dayjs from 'dayjs';
import 'dayjs/locale/ja';

dayjs.locale('ja');

//Date型を第二引数の表示形式(YYYY/MM/DD,YYYY年MM月DD日など)に変換する関数。dayjsを使用。
export const formatDate = (date: Date | string | null, format: string) => {
  if (!date) return '';
  return dayjs(date).format(format);
};
