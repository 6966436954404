import { SiteList } from '@/features/saveSite/types';
import { axios } from '@/lib/axios';
import { OutputErrorLog } from '@/lib/error';
import { formatDate } from '@/utils/formatDate';
import { GenerateAPIHeaderRequest, generateAPIHeader } from '@/utils/generateAPIHeader';

type GetSitesParams = Partial<{
  siteNamePartial: string;
  excludesExpiredSites: boolean;
  headers: GenerateAPIHeaderRequest;
}>;

/**
 * 検索条件（工事名の部分一致、期間外の工事を除外するか）に合致する工事を取得するAPI
 * 検索条件が設定されていない場合は期間内の全ての工事を取得する
 */
export const getSites = async ({
  siteNamePartial = '',
  excludesExpiredSites = true,
  headers,
}: GetSitesParams = {}) => {
  try {
    const today = formatDate(new Date(), 'YYYY-MM-DD');
    const commonUrl = `/sites?sortType=siteName-asc&siteNamePartial=${siteNamePartial}`;
    const requestUrl = excludesExpiredSites ? `${commonUrl}&scheduledDate=${today}` : commonUrl;

    const apiHeaders = generateAPIHeader(headers);

    const response = await axios.get<SiteList>(requestUrl, apiHeaders);
    return response.data;
  } catch (error) {
    OutputErrorLog('getSites', error);
    return [];
  }
};
