import { useEffect, useState } from 'react';

export const usePagination = <T>(data: T[]) => {
  // データの総数
  const dataTotal = data.length;
  // ページネーションで選択されているページ
  const [activePage, setPage] = useState(1);
  // ページネーションの総数
  const paginationTotal = Math.ceil(dataTotal / 10);

  // 画面表示されているデータの範囲（例：22件中 11 - 20件表示）
  const displayedDataRange = (() => {
    if (dataTotal === 0) return '0件中 0 - 0件表示';
    const start = (activePage - 1) * 10 + 1;
    const end = activePage * 10 < dataTotal ? activePage * 10 : dataTotal;
    return `${dataTotal}件中 ${start} - ${end}件表示`;
  })();

  // 画面表示されているデータリスト（最大10件）
  const displayedDataList = (() => {
    const start = (activePage - 1) * 10;
    const end = activePage * 10 < dataTotal ? activePage * 10 : dataTotal;
    return data.slice(start, end);
  })();

  // データが変わったらページネーションを１ページに戻す
  useEffect(() => {
    setPage(1);
  }, [data]);

  return {
    activePage,
    setPage,
    paginationTotal,
    displayedDataRange,
    displayedDataList,
  };
};
