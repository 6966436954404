import { DataFileSchemaType } from '../components/Data3dRegistration/UploadDataFile';
import { DataFileOrder, DataFileType, RecruitmentCriteria } from '../types';

export const generateDefaultDataByDataFileType = (
  dataFileType: DataFileType,
  defaultValues?: DataFileSchemaType
): DataFileSchemaType => ({
  ...defaultValues,
  typeId: dataFileType,
  fileName: defaultValues?.fileName || '',
  pointName: defaultValues?.pointName,
  //数学座標をデフォルト値として設定
  x: defaultValues?.x || 0,
  y: defaultValues?.y || 1,
  z: defaultValues?.z || 2,
  light: defaultValues?.light,
  r: defaultValues?.r,
  g: defaultValues?.g,
  b: defaultValues?.b,
  date: defaultValues?.date,
  shouldExcludeFirstRow: false,
  measurementDate: defaultValues?.measurementDate || undefined,
  taskIdList: defaultValues?.taskIdList || [],

  //出来高データの場合は、統合・採用基準・ORDERに初期値を設ける
  isIntegrated:
    defaultValues?.isConnectSiteLink || dataFileType === DataFileType.AS_BUILT ? true : undefined,
  recruitmentCriteriaId:
    defaultValues?.recruitmentCriteriaId || dataFileType === DataFileType.AS_BUILT
      ? RecruitmentCriteria.LOWEST_POINT
      : undefined,
  order: defaultValues?.order || DataFileOrder.MIN,
  //起工測量データ・設計データの場合は、SL連携を初期値として設定
  isConnectSiteLink:
    defaultValues?.isConnectSiteLink ||
    dataFileType === DataFileType.GROUND_SURVEY ||
    dataFileType === DataFileType.DESIGN
      ? true
      : undefined,
});
