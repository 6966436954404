import { PAGE_PATHS, SYSTEM_ROLE_ID } from './constant';

import { MeUser } from '@/features/auth/types';

/**
 * 工事一覧・工事詳細・ユーザー一覧・所有者ユーザー一覧
 */
export type PageType = 'sitesParent' | 'sitesChild' | 'users' | 'ownerUsers';

type GetGlobalMenuLinksProps = {
  user: MeUser;
  siteId?: number;
  currentPath: string;
  pageType: PageType;
};

type GlobalMenuLink = {
  link: string;
  label: string;
  imagePath: string;
  isActive: boolean;
};

type ExtendedGlobalMenuLink = GlobalMenuLink & {
  displayedRoles: number[] | 'all';
  pageType: PageType[];
};

export const getGlobalMenuLinks = ({
  user,
  siteId,
  currentPath,
  pageType,
}: GetGlobalMenuLinksProps): GlobalMenuLink[] => {
  //主にユーザーの権限・pageTypeによって表示するメニューを変更する
  const userRole = user.systemRoleTypeId;
  const globalMenuLinks: ExtendedGlobalMenuLink[] = [
    {
      link: `${PAGE_PATHS.SITES.LIST}/${siteId}`,
      label: 'Home',
      imagePath: '/images/home.svg',
      isActive: currentPath === `${PAGE_PATHS.SITES.LIST}/[siteId]`,
      displayedRoles: 'all',
      pageType: ['sitesChild'],
    },
    {
      link: `${PAGE_PATHS.SITES.LIST}/${siteId}/edit`,
      label: '工事設定',
      imagePath: '/images/settings.svg',
      isActive: currentPath === `${PAGE_PATHS.SITES.LIST}/[siteId]/edit`,
      displayedRoles: [SYSTEM_ROLE_ID.TENANT_ADMIN_USER.id, SYSTEM_ROLE_ID.GENERAL_USER.id],
      pageType: ['sitesChild'],
    },
    {
      link: `${PAGE_PATHS.SITES.LIST}/${siteId}/schedule`,
      label: 'スケジュール',
      imagePath: '/images/schedule.svg',
      isActive: currentPath === `${PAGE_PATHS.SITES.LIST}/[siteId]/schedule`,
      displayedRoles: 'all',
      pageType: ['sitesChild'],
    },

    {
      link: `${PAGE_PATHS.SITES.LIST}/${siteId}/finished-shapes`,
      label: '出来形管理',
      imagePath: '/images/finished-shape.svg',
      isActive: currentPath === `${PAGE_PATHS.SITES.LIST}/[siteId]/finished-shapes`,
      displayedRoles: 'all',
      pageType: ['sitesChild'],
    },
    {
      link: PAGE_PATHS.SITES.LIST,
      label: '工事一覧',
      imagePath: '/images/site-list.svg',
      isActive: currentPath === PAGE_PATHS.SITES.LIST,
      displayedRoles: [SYSTEM_ROLE_ID.TENANT_ADMIN_USER.id, SYSTEM_ROLE_ID.GENERAL_USER.id],
      pageType: ['sitesParent', 'users'],
    },
    {
      link: PAGE_PATHS.USERS.LIST,
      label: 'ユーザー管理',
      imagePath: '/images/user-management.svg',
      isActive: currentPath === PAGE_PATHS.USERS.LIST,
      displayedRoles: [SYSTEM_ROLE_ID.TENANT_ADMIN_USER.id],
      pageType: ['sitesParent', 'users'],
    },
    {
      link: PAGE_PATHS.USERS.ME,
      label: 'ユーザー設定',
      imagePath: '/images/user-management.svg',
      isActive: currentPath === PAGE_PATHS.USERS.LIST,
      displayedRoles: [SYSTEM_ROLE_ID.GENERAL_USER.id],
      pageType: ['sitesParent', 'users'],
    },
  ];
  //userRoleとpageTypeによって表示するメニューを変更する
  const filteredGlobalMenuLinks = globalMenuLinks.filter((globalMenuLink) => {
    const isPageTypeOK = globalMenuLink.pageType.includes(pageType);
    const isUserRoleOK =
      globalMenuLink.displayedRoles === 'all' || globalMenuLink.displayedRoles.includes(userRole);
    return isPageTypeOK && isUserRoleOK;
  });
  return filteredGlobalMenuLinks;
};
