import { useEffect, useState } from 'react';

import useSWR from 'swr';

import { getUser } from '@/features/auth/api/getUser';
import { useAuthorizeRouter } from '@/features/auth/hooks/useAuthorizeRouter';
import { getSite } from '@/features/saveSite/api/getSite';
import { OutputErrorLog } from '@/lib/error';
import { PAGE_PATHS, SERVICE_NAME, SWR_KEYS } from '@/utils/constant';

export const useHeadMetaData = () => {
  const router = useAuthorizeRouter();
  const path = router.pathname;
  const { siteId } = router.query;

  // 必ずしもsites/配下のページにいるとは限らないため、条件分岐を行う。
  const { data: site } = useSWR([SWR_KEYS.SITE, siteId], () =>
    siteId ? getSite({ siteId }) : null
  );
  // getUserを呼び出してよいページか判定(トップページ、ログインページ、認証ページはエラーとなるので不可)
  const canCallGetUserPages = !(
    path === PAGE_PATHS.TOP ||
    path === PAGE_PATHS.LOGIN ||
    path === PAGE_PATHS.SITELINK ||
    path === PAGE_PATHS.AUTH
  );

  const { data: user } = useSWR(SWR_KEYS.USER, () => (canCallGetUserPages ? getUser() : null));

  const appName = SERVICE_NAME.SITENOW;
  const [title, setTitle] = useState(appName);
  const siteName = site?.siteName || '';
  const companyName = user?.companyName || '';

  useEffect(() => {
    try {
      switch (path) {
        case PAGE_PATHS.LOGIN:
          setTitle(`${appName} 施工現場の見える化 | トプコンソキアポジショニングジャパン`);
          break;
        case PAGE_PATHS.SITES.LIST:
          setTitle(`${companyName} | 工事一覧 | ${appName}`);
          break;
        case PAGE_PATHS.SITES.NEW:
          setTitle(`${companyName} | 工事新規作成 | ${appName}`);
          break;
        case PAGE_PATHS.SITES.EDIT:
          setTitle(`${siteName} | 工事設定 | ${appName}`);
          break;
        case PAGE_PATHS.SITES.SHOW:
          setTitle(`${siteName} | 工事トップ | ${appName}`);
          break;
        case PAGE_PATHS.SITES.SCHEDULE:
          setTitle(`${siteName} | スケジュール | ${appName}`);
          break;
        case PAGE_PATHS.SITES.FINISHED_SHAPE_PROGRESS:
          setTitle(`${siteName} | 出来高進捗 | ${appName}`);
          break;
        case PAGE_PATHS.SITES.FINISHED_SHAPES.LIST:
          setTitle(`${siteName} | 出来形管理一覧 | ${appName}`);
          break;
        case PAGE_PATHS.SITES.FINISHED_SHAPES.REPORTS:
          setTitle(`${siteName} | 出来形管理帳票3D詳細 | ${appName}`);
          break;
        case PAGE_PATHS.SITES.FINISHED_SHAPES.NEW:
          setTitle(`${siteName} | 出来形管理帳票作成 | ${appName}`);
          break;
        case PAGE_PATHS.SITES.FINISHED_SHAPES.DETAIL:
          setTitle(`${siteName} | 出来形管理帳票 | ${appName}`);
          break;
        case PAGE_PATHS.SITES.FINISHED_SHAPES.EDIT:
          setTitle(`${siteName} | 出来形管理帳票 | ${appName}`);
          break;
        case PAGE_PATHS.USERS.LIST:
          setTitle(`${companyName} | ユーザー管理 | ${appName}`);
          break;
        case PAGE_PATHS.USERS.NEW:
          setTitle(`${companyName} | ユーザー新規登録 | ${appName}`);
          break;
        case PAGE_PATHS.USERS.EDIT:
          setTitle(`${companyName} | ユーザー設定 | ${appName}`);
          break;
        case PAGE_PATHS.USERS.ME:
          setTitle(`${companyName} | ユーザー設定 | ${appName}`);
          break;
        case PAGE_PATHS.OWNER_USERS.LIST:
          setTitle(`システム管理者 | ${appName}`);
          break;
        case PAGE_PATHS.OWNER_USERS.NEW:
          setTitle(`所有者ユーザー新規作成 | ${appName}`);
          break;
        case PAGE_PATHS.OWNER_USERS.EDIT:
          setTitle(`所有者ユーザー設定 | ${appName}`);
          break;
        case PAGE_PATHS.SITELINK:
          setTitle(`認証結果 | ${appName}`);
          break;
      }
    } catch (error) {
      OutputErrorLog('Set Head Effect', error);
    }
  }, [path, site, user]);

  return title;
};
