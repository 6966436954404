import { useRef } from 'react';

import { useViewportSize } from '@mantine/hooks';

export const useScrollArea = (options = { underScrollAreaHeight: 0 }) => {
  const { height: viewportHeight } = useViewportSize();
  const scrollAreaRef = useRef<HTMLDivElement | null>(null);
  const aboveScrollAreaHeight = scrollAreaRef.current?.getBoundingClientRect()?.y || 0;
  const scrollAreaHeight = viewportHeight - aboveScrollAreaHeight - options.underScrollAreaHeight;

  return { scrollAreaRef, scrollAreaHeight };
};
