import { useEffect } from 'react';

import { notifications } from '@mantine/notifications';
import { useSearchParams } from 'next/navigation';
import useSWR from 'swr';

import { getUser } from '@/features/auth/api/getUser';
import { useAuthorizeRouter } from '@/features/auth/hooks/useAuthorizeRouter';
import { getSite } from '@/features/saveSite/api/getSite';
import { useSiteLinkValid } from '@/features/sitelink/hooks/useSitelinkAuth';
import { AUTH_FAILURE, PAGE_PATHS, QUERY_STRING, SERVICE_NAME, SWR_KEYS } from '@/utils/constant';

export const ClientAuthorizeProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // ブラウザ復帰時用に、クライアント側でも認証チェックを行う。middlwewareでの認証チェックは、サーバー側でのみ行う。
  //https://swr.vercel.app/ja/examples/auth

  const router = useAuthorizeRouter();
  const path = router.pathname;
  const { siteId } = router.query;
  const isNoAuthorizePage =
    path === PAGE_PATHS.LOGIN || path === PAGE_PATHS.TOP || path === PAGE_PATHS.SITELINK;
  const { data, isLoading } = useSWR(SWR_KEYS.USER, () => (isNoAuthorizePage ? null : getUser()));
  useEffect(() => {
    //isNoAuthorizePageの場合は、認証チェックを行わない
    if (isNoAuthorizePage) {
      return;
    }

    if (!isLoading && !data) {
      router.push({
        pathname: '/login',
        options: {
          addQuery: {
            [QUERY_STRING.AUTH_FAILURE_TYPE]: AUTH_FAILURE.UNAUTHED,
          },
        },
      });
    }
  }, [isLoading, data]);
  const { data: site } = useSWR([SWR_KEYS.SITE, siteId], () =>
    siteId ? getSite({ siteId }) : null
  );
  const searchParams = useSearchParams();
  const currentPath = router.pathname;
  const typeParam = searchParams.get('type');

  const { isValid, invalidState, isLoading: isLoadingSiteLink } = useSiteLinkValid();
  useEffect(() => {
    if (site?.slSiteId && siteId && !isLoadingSiteLink) {
      const isTypeParamSiteLinkApiUserPolicy = typeParam === 'siteLinkApiUserPolicy';
      if (invalidState?.slApiUserScope && !isTypeParamSiteLinkApiUserPolicy) {
        //API USER SCOPE設定ページへ、強制的に遷移する
        router.push({
          pathname: `/sites/${siteId}/edit`,
          options: {
            addQuery: {
              type: 'siteLinkApiUserPolicy',
            },
          },
        });
        notifications.show({
          title: `${SERVICE_NAME.SITELINK}認証エラー`,
          message: `API USER POLICYが設定されていないため、${SERVICE_NAME.SITELINK}に接続できません`,
          color: 'red',
        });
      }
      if (invalidState?.slSyncStatus) {
        notifications.show({
          title: `${SERVICE_NAME.SITELINK}認証エラー`,
          message: `${SERVICE_NAME.SITELINK}との同期に失敗しているため、${SERVICE_NAME.SITELINK}に接続できません`,
          color: 'red',
        });
      }
    }
  }, [siteId, isLoadingSiteLink, currentPath]);
  return <>{children}</>;
};
