// eslint-disable-next-line max-classes-per-file
import { logger } from '../../next-logger.config';

import { SERVICE_NAME } from '@/utils/constant';

export class RedirectError extends Error {
  url: string;
  constructor(message: string, url: string) {
    super(message);
    this.name = 'RedirectError';
    this.url = url;
  }
}

export class AuthorizationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AuthorizationError';
  }
}

export class NotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NotFoundError';
  }
}

export class BadRequestError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'BadRequestError';
  }
}

export class ServerError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ServerError';
  }
}

export class NetWorkError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NetWorkError';
  }
}

//MEMO 小文字に修正するかもしれない
export const OutputErrorLog = (name: string, error: unknown) => {
  logger.error(`error occured in ${name}`);
  if (error instanceof Error) {
    logger.error(error.message); //errorがErrorクラスである場合messageがフィールドに含まれることが保証されるので型安全
  } else if (typeof error === 'string') {
    logger.error(error);
  } else {
    logger.error('unexpected error');
  }
};

export const getSensorError = (description: string) => {
  switch (description) {
    case 'UNAUTHORIZED':
      return {
        title: '失敗',
        description: 'PASSWORDに誤りがあります。',
      };
    case 'BAD_REQUEST':
      return {
        title: '失敗',
        description: '機器番号・API KEY・USER IDのいずれかに誤りがあります。',
      };
    case 'TOO_MANY_REQUESTS':
      return {
        title: '失敗',
        description: 'リクエストが多すぎます。時間をおいて再度お試しください。',
      };
    case 'SERVICE_UNAVAILABLE':
      return {
        title: '失敗',
        description: '現在おんどとりサービスを利用できません。時間をおいて再度お試しください。',
      };
    default:
      return {
        title: '失敗',
        description: '予期せぬエラーが発生しました。',
      };
  }
};

export const getSiteError = ({
  message,
  description,
  isEdit,
}: {
  message?: string;
  description?: string;
  isEdit?: boolean;
}) => {
  if (message?.includes('sitelink-client error')) {
    if (description?.includes('Required geoid')) {
      return {
        title: '失敗',
        description:
          'Localizationファイルを登録できませんでした。Geoidファイルが不要なLocalizationファイルをご登録ください。',
      };
    }
    if (description?.includes('Failed to convert TP3 to GC3')) {
      return {
        title: '失敗',
        description:
          'Localizationファイルを登録できませんでした。ファイルの中身が正しいかをご確認ください。',
      };
    }
    return {
      title: '失敗',
      description: `${SERVICE_NAME.SITELINK}の情報取得に失敗しました。`,
    };
  }
  switch (message) {
    case 'company active site limit error':
      return {
        title: '失敗',
        description: 'アクティブな工事数が上限に達しています。',
      };
    case 'api user scope is invalid':
      return {
        title: '失敗',
        description: 'API USER POLICYが不正です。',
      };

    default:
      return {
        title: '失敗',
        description: isEdit ? '工事の更新に失敗しました。' : '工事の登録に失敗しました。',
      };
  }
};

export const getUserError = (message?: string) => {
  if (message === 'user account already exists') {
    return {
      title: '失敗',
      description: 'ユーザーが既に存在します。',
    };
  }
  return {
    title: '失敗',
    description: 'ユーザーを追加できませんでした',
  };
};
