import { logger } from '../../../next-logger.config';
import { OutputErrorLog } from '../error';

import { getUser } from '@/features/auth/api/getUser';
import { AUTH_FAILURE, PAGE_PATHS, QUERY_STRING, SYSTEM_ROLE_ID } from '@/utils/constant';

export const listener = async (data: any) => {
  switch (data?.payload?.event) {
    case 'customOAuthState': {
      const currentUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      try {
        const jsonCompanyCode = data.payload.data;
        const { companyCode } = JSON.parse(jsonCompanyCode);

        //システム管理者など、companyCodeが意図的に存在しない場合もある
        if (companyCode) {
          //現在のページのURLにcompanyCodeを付与してリダイレクトする

          const newUrl = `${currentUrl}/sites?companyCode=${companyCode}`;
          window.location.href = newUrl;
          return;
        }
        //システム管理者の場合、所有者ユーザー設定ページへリダイレクトする
        const user = await getUser();

        if (!user) {
          throw new Error('user is undefined');
        }

        if (user.systemRoleTypeId === SYSTEM_ROLE_ID.SYSTEM_ADMIN_USER.id) {
          const newUrl = `${currentUrl}/${PAGE_PATHS.OWNER_USERS.LIST}`;
          window.location.href = newUrl;
          return;
        }

        //両方に該当しない場合、ログインページへリダイレクトする
        const newUrl = `${currentUrl}/login?${QUERY_STRING.AUTH_FAILURE_TYPE}=${AUTH_FAILURE.UNAUTHED}`;
        window.location.href = newUrl;
      } catch (error) {
        OutputErrorLog('customOAuthState', error);
        const newUrl = `${currentUrl}/login?${QUERY_STRING.AUTH_FAILURE_TYPE}=${AUTH_FAILURE.UNAUTHED}`;
        window.location.href = newUrl;
      }

      logger.info('custom state returned from CognitoHosted UI');
      break;
    }
    case 'tokenRefresh_failure': {
      const currentUrl = `${window.location.protocol}//${window.location.host}`;
      logger.error('token refresh failed');
      //ログインページへリダイレクトする
      const newUrl = `${currentUrl}/login?${QUERY_STRING.AUTH_FAILURE_TYPE}=${AUTH_FAILURE.UNAUTHED}`;
      window.location.href = newUrl;
      break;
    }
  }
};
